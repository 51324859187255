const SortArray = {
  multiplyArraySort: function (array: any[], key: any[], orderby: any[]) {
    array = array.sort((a, b) => {
      for (const k in key) {
        const o1 = a[key[k]];
        const o2 = b[key[k]];
        const _order = orderby[k];
        if (o1 > o2) return _order == "desc" ? 1 : -1;
        if (o1 < o2) return _order == "asc" ? -1 : 1;
      }
      return 0;
    });
    return array;
  },
  SingleArraySort: function (array: any[], key1: string) {
    array = array.sort((a, b) => {
      const o1 = a[key1].toString().toLowerCase();
      const o2 = b[key1].toString().toLowerCase();

      if (o1 > o2) return -1;
      if (o1 < o2) return 1;

      return 0;
    });
    return array;
  },
  SingleArrayReversSort: function (array: any[], key1: string) {
    array = array.sort((a, b) => {
      const o1 = a[key1];
      const o2 = b[key1];

      if (o1 < o2) return -1;
      if (o1 > o2) return 1;

      return 0;
    });
    return array;
  },
};
export default SortArray;

function sortByPriority(matched: any[], remainingPriorities: string | any[]) {
  throw new Error("Function not implemented.");
}
