<template>
  <div :class="value == undefined ||value.length == 0 ? 'd-none' : ''">
    <Label :label="$t(label)" class="mb-4 fnt-subtitle fnt-medium" />
    <div class="rounded-2 bg-black-200 p-3">
      <template v-for="(items, index) in value" :key="index">
        <div
          class="d-flex align-items-start list-department"
          :style="index != 0 ? `margin-left:calc(24px * ${index - 1})` : ''"
        >
          <div class="line" :class="index == 0 ? 'd-none' : ''">
            <div class="line-1"></div>
          </div>
          <div class="">
            <span :style="device == 'mobile' ? 'font-size:12px !important;':''" class="fnt-regular">{{ items.strRootname }}</span>
          </div>
        </div>
        <!-- <template v-for="(item, index1) in items[index]" :key="index1">
        <div class="d-flex align-items-center list-department" style="display:none;">
          <div class="d-flex align-items-center">
            {{ index1 }}
            <div class="line" >
              <div class="line-1"></div>
            </div>
            <span>{{ item }}</span>
          </div>
        </div>
      </template> -->
      </template>
    </div>
  </div>
  <!-- Description (อ่านก่อน)
    ถ้ามีตัวย่อยให้เอา index * 24px ไปเรื่อย ๆ
  -->
</template>

<script>
import Label from "@/components/label/label.vue";
export default {
  props: {
    inactive: { type: Boolean, default: false },
    value: { type: Object },
    label: { type: String },
    classlabel: { type: String },
  },
  data() {
    return {
      num: 2,
    };
  },
  methods: {},
  components: {
    Label,
  },
};
</script>

<style lang="scss" scoepd>
.line {
  width: 24px;
  // background-color:red;
  height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  margin-bottom: 8px;
  border-radius: 4px;
  .line-1 {
    width: 1px;
    background-color: $black-500;
    height: 100%;
    border-radius: 4px;
    &::before {
      content: "";
      width: 12px;
      height: 1px;
      background-color: $black-500;
      position: absolute;
      right: 0px;
      bottom: 0;
      border-radius: 4px;
    }
  }
}
.list-department:last-child {
  color: $primary;
  font-weight: 600;
}
</style>
