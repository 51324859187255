<template>
  <div class="mb-3">
    <label class="txt-inp">
      <input
        type="email"
        required
        :name="name"
        :id="name"
        :value="value"
        v-on:keypress="isEmailValid()"
        :class="isEmailValid() ? 'invalid' : ''"
      />

      <p class="label-text">{{  $t(label) }}</p>
      <div
        v-if="isEmailValid()"
        class="d-flex alertInput align-items-center p-0 mt-1"
      >
        <Icon icon="carbon:warning" class="me-1" />
        {{ alertMsg }}
      </div>
    </label>
  </div>
  <!-- </div> -->
</template>

<script>
import Validation from "../../typescript/validator/validator";

export default {
  components: {},
  props: {
    name: { type: String },
    label: { type: String },
    value: { type: String },
    validate: { type: String },
    hint: {
      type: String,
      default: "input",
    },
    alertMsg: { type: String },
  },

  methods: {
    isEmailValid: function () {
      if (this.value != null) {
        return Validation[this.validate](this.value);
      }
    },
  },
};
</script>


<style lang="scss" scoped>

</style>
