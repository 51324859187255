const apicampus = {
  insertCampus: "/v1/api/AdsCampus/InsertCampus?MemberID=",
  updateCampus: "/v1/api/AdsCampus/UpdateCampus/",
  deleteCampus: "/v1/api/AdsCampus/DeleteCampus/",
  getCampusList: "/v1/api/AdsCampus/CampusList",
  getCampusByID: "/v1/api/AdsCampus/CampusByID/",
  duplicate: "/v1/api/AdsCampus/DuplicateCampus",
  checkdDeleteCampus: "/v1/api/AdsCampus/CheckDeleteCampus/",
  checkusedstatus: "/v1/api/AdsCampus/CheckUsedCampus/",
};

export default apicampus;
