<template>
  <!-- {{haveAlertField()}} || {{haveRequiredEmpty()}} -->
  <!-- <div v-for="strField in request" :key="strField">
    {{ strField }}: {{ data[strField] }} 
  </div> -->
  <template v-for="(schema_first, index_1) in typeSchema" :key="index_1">
    <template v-for="(group, index_2) in schema_first.group" :key="index_2">
      <div :class="group.class" v-if="hide == false">
        <template v-for="(group, index_3) in group.group" :key="index_3">
          <div :class="group.class">
            <template v-for="(group, index_4) in group.group" :key="index_4">
              <!-- <div :class="group.class"> -->
              <template v-for="(group, index_5) in group.group" :key="index_5">
                <div :class="group.class">
                  <template
                    v-for="(field, index_6) in group.component"
                    :key="index_6"
                  >
                  <!-- {{  data[field.datavalue]}} -->
                    <component
                      v-if="field.state == null"
                      :is="field.type"
                      :value="data[field.datavalue]"
                      :selectedValue="data[field.datavalue]"
                      :DropdownItem="data[field.listworktype]"
                      @input="updateData(field.datavalue, $event, field.type)"
                      v-bind="field"
                      :data="data"
                      :ref="field.datavalue"
                    >
                    </component>

                    <component
                      v-if="field.state == 'address'"
                      :is="field.type"
                      :value="data[field.datavalue]"
                      :selectedValue="data[field.datavalue]"
                      @input="
                        updateData(field.datavalue, $event, field.type),
                          selecteddropdown($event, index_5)
                      "
                      @load="getUserfieldAddress(index_5)"
                      :DropdownItem="listItemsFieldSet[index_5]"
                      :defaultLang="field.defaultLang"
                      :blnAddress="field.blnAddress"
                      v-bind="field"
                      :data="data"
                      :ref="field.datavalue"
                      :showTxtInp="
                        field.datavalue == 'intSubdistrict' &&
                        ($i18n.locale == 'th' || $i18n.locale == 'TH')
                          ? false
                          : field.datavalue == 'strSubdistrict' &&
                            ($i18n.locale == 'en' || $i18n.locale == 'EN')
                          ? false
                          : true
                      "
                    >
                    </component>

                    <!-- กรณีเป็นปุ่มสำหรับ submit -->
                    <component
                      v-if="field.state == 'submit'"
                      :is="field.type"
                      @input="
                        haveAlertField() || haveRequiredEmpty()
                          ? null
                          : addData()
                      "
                      v-bind="field"
                      :data="data"
                      :ref="field.datavalue"
                      :class="
                        haveAlertField() || haveRequiredEmpty()
                          ? 'disable-submit bg-black-300 text-white'
                          : 'btn-primary'
                      "
                    >
                    </component>

                    <!-- กรณีเป็นปุ่มสำหรับ ยกเลิก -->
                    <component
                      v-if="field.state == 'cancel'"
                      :is="field.type"
                      @input="closeForm()"
                      v-bind="field"
                      :data="data"
                      :ref="field.datavalue"
                    >
                    </component>
                    <!-- กรณีเป็นปุ่มสำหรับ delete -->
                    <component
                      v-if="field.state == 'delete'"
                      :is="field.type"
                      @input="deleteData()"
                      v-bind="field"
                      :data="data"
                      :ref="field.datavalue"
                    >
                    </component>
                  </template>
                </div>
              </template>
              <template
                v-for="(field, index_7) in group.component"
                :key="index_7"
              >
                <component
                  v-if="field.state == null"
                  :is="field.type"
                  :value="data[field.datavalue]"
                  :selectedValue="data[field.datavalue]"
                  @input="updateData(field.datavalue, $event, field.type)"
                  v-bind="field"
                  :data="data"
                  :ref="field.datavalue"
                >
                </component>
                <component
                  v-if="field.state == 'address'"
                  :is="field.type"
                  :value="data[field.datavalue]"
                  :selectedValue="data[field.datavalue]"
                  @input="
                    updateData(field.datavalue, $event, field.type),
                      selecteddropdown($event, index)
                  "
                  @load="getUserfieldAddress(index)"
                  :DropdownItem="listItemsFieldSet[index]"
                  v-bind="field"
                  :data="data"
                  :ref="field.datavalue"
                  :showTxtInp="
                    field.datavalue == 'intSubdistrict' &&
                    ($i18n.locale == 'th' || $i18n.locale == 'TH')
                      ? false
                      : field.datavalue == 'strSubdistrict' &&
                        ($i18n.locale == 'en' || $i18n.locale == 'EN')
                      ? false
                      : true
                  "
                >
                </component>
                <!-- กรณีเป็นปุ่มสำหรับ submit -->
                <component
                  v-if="field.state == 'submit'"
                  :is="field.type"
                  @input="
                    haveAlertField() || haveRequiredEmpty() ? null : addData()
                  "
                  v-bind="field"
                  :data="data"
                  :ref="field.datavalue"
                  :class="
                    haveAlertField() || haveRequiredEmpty()
                      ? 'disable-submit bg-black-300 text-white'
                      : 'btn-primary'
                  "
                >
                </component>

                <!-- กรณีเป็นปุ่มสำหรับ ยกเลิก -->
                <component
                  v-if="field.state == 'cancel'"
                  :is="field.type"
                  @input="closeForm()"
                  v-bind="field"
                  :data="data"
                  :ref="field.datavalue"
                >
                </component>
                <!-- กรณีเป็นปุ่มสำหรับ delete -->
                <component
                  v-if="field.state == 'delete'"
                  :is="field.type"
                  @input="deleteData()"
                  v-bind="field"
                  :data="data"
                  :ref="field.datavalue"
                >
                </component>
              </template>
              <!-- </div> -->
            </template>
          </div>

          <template v-for="(field, index_8) in group.component" :key="index_8">
            <component
              v-if="field.state == null"
              :selectedValue="data[field.datavalue]"
              :is="field.type"
              :value="data[field.datavalue]"
              @input="updateData(field.datavalue, $event, field.type)"
              v-bind="field"
              :data="data"
              :ref="field.datavalue"
            >
            </component>

            <component
              v-if="field.state == 'address'"
              :is="field.type"
              :value="data[field.datavalue]"
              :selectedValue="data[field.datavalue]"
              @input="
                updateData(field.datavalue, $event, field.type),
                  selecteddropdown($event, index_8)
              "
              @load="getUserfieldAddress(index_8)"
              :DropdownItem="listItemsFieldSet[index_8]"
              v-bind="field"
              :data="data"
              :ref="field.datavalue"
              :showTxtInp="
                field.datavalue == 'intSubdistrict' &&
                ($i18n.locale == 'th' || $i18n.locale == 'TH')
                  ? false
                  : field.datavalue == 'strSubdistrict' &&
                    ($i18n.locale == 'en' || $i18n.locale == 'EN')
                  ? false
                  : true
              "
            >
            </component>
            <!-- กรณีเป็นปุ่มสำหรับ submit -->
            <component
              v-if="field.state == 'submit'"
              :is="field.type"
              @input="
                haveAlertField() || haveRequiredEmpty() ? null : addData()
              "
              v-bind="field"
              :data="data"
              :ref="field.datavalue"
              :class="
                haveAlertField() || haveRequiredEmpty()
                  ? 'disable-submit bg-black-300 text-white'
                  : 'btn-primary'
              "
            >
            </component>

            <!-- กรณีเป็นปุ่มสำหรับ ยกเลิก -->
            <component
              v-if="field.state == 'cancel'"
              :is="field.type"
              @input="closeForm()"
              v-bind="field"
              :data="data"
              :ref="field.datavalue"
            >
            </component>
            <!-- กรณีเป็นปุ่มสำหรับ delete -->
            <component
              v-if="field.state == 'delete'"
              :is="field.type"
              @input="deleteData()"
              v-bind="field"
              :data="data"
              :ref="field.datavalue"
            >
            </component>
          </template>
        </template>
        <template v-for="(field, index_9) in group.component" :key="index_9">
          <component
            v-if="field.state == null"
            :is="field.type"
            :value="data[field.datavalue]"
            @input="updateData(field.datavalue, $event, field.type)"
            :selectedValue="data[field.datavalue]"
            v-bind="field"
            :data="data"
            :ref="field.datavalue"
          >
          </component>
          <component
            v-if="field.state == 'address'"
            :is="field.type"
            :value="data[field.datavalue]"
            :selectedValue="data[field.datavalue]"
            @input="
              updateData(field.datavalue, $event, field.type),
                selecteddropdown($event, index_9)
            "
            @load="getUserfieldAddress(index_9)"
            :DropdownItem="listItemsFieldSet[index]"
            v-bind="field"
            :data="data"
            :ref="field.datavalue"
            :showTxtInp="
              field.datavalue == 'intSubdistrict' &&
              ($i18n.locale == 'th' || $i18n.locale == 'TH')
                ? false
                : field.datavalue == 'strSubdistrict' &&
                  ($i18n.locale == 'en' || $i18n.locale == 'EN')
                ? false
                : true
            "
          >
          </component>
          <!-- กรณีเป็นปุ่มสำหรับ submit -->
          <component
            v-if="field.state == 'submit'"
            :is="field.type"
            @input="haveAlertField() || haveRequiredEmpty() ? null : addData()"
            v-bind="field"
            :data="data"
            :ref="field.datavalue"
            :class="
              haveAlertField() || haveRequiredEmpty()
                ? 'disable-submit bg-black-300 text-white'
                : 'btn-primary'
            "
          >
          </component>

          <!-- กรณีเป็นปุ่มสำหรับ ยกเลิก -->
          <component
            v-if="field.state == 'cancel'"
            :is="field.type"
            @input="closeForm()"
            v-bind="field"
            :data="data"
            :ref="field.datavalue"
          >
          </component>
          <!-- กรณีเป็นปุ่มสำหรับ delete -->
          <component
            v-if="field.state == 'delete'"
            :is="field.type"
            @input="deleteData()"
            v-bind="field"
            :data="data"
            :ref="field.datavalue"
          >
          </component>
        </template>
      </div>
    </template>
  </template>
</template>

<script>
import DynamicRenderer from "./DynamicRenderer.vue";
import InputFile from "../input/inputFile.vue";
import InputLongText from "../input/inputLongText.vue";
import InputPassword from "../input/inputPassword.vue";
import InputText from "../input/inputText.vue";
import InputSearch from "../input/inputSearch.vue";
import InputPhoneNumber from "../input/inputPhoneNumber.vue";
import Button from "../../components/button/button.vue";
import InputEmail from "../../components/input/inputEmail.vue";
import HeadLabel from "../../components/label/label.vue";
import InputFacebook from "../../components/input/inputFacebook.vue";
import Dropdown from "../../components/input/selectDropdown.vue";
import Toggle from "../input/toggle/toggleForm.vue";
import Image from "../image.vue";
import InputNumber from "../../components/input/inputNumber.vue";
import PhoneNumber from "../../components/input/PhoneNumber.vue";
import Selectdropdown from "../../components/input/dropdown/select.vue";
import InputPrefix from "@/components/input/inputPrefix.vue";
import DepartmentRoot from "@/components/departmentRoot.vue";
import InputWorkPeriod from "@/components/input/inputWorkPeriod.vue";

import inputDynamicSizeVue from "../input/inputDynamicSize.vue";

import urlapi from "@/typescript/urlapi/url";
import axios from "axios";
import DeleteinForm from "@/components/button/deleteinform.vue";

export default {
  components: {
    InputWorkPeriod,
    DynamicRenderer,
    InputFile,
    InputLongText,
    InputPassword,
    InputText,
    InputSearch,
    InputPhoneNumber,
    Button,
    InputEmail,
    DeleteinForm,
    HeadLabel,
    InputFacebook,
    Dropdown,
    Image,
    Toggle,
    InputNumber,
    PhoneNumber,
    Selectdropdown,
    InputPrefix,
    DepartmentRoot,
    inputDynamicSizeVue,
  },
  name: "DynamicRenderer",
  emits: ["input", "close", "post", "delete"],
  props: {
    typeSchema: {
      type: Array,
    },
    data: {
      type: Object,
    },
    request: {
      type: Array,
    },
    action: { type: String },
  },

  data() {
    return {
      listItemsFieldSet: [],
      listUserFieldSet: [],
      locales: null,
      hide: false,
    };
  },
  watch: {
    locales() {
      this.hide = true;
      setTimeout(() => {
        this.hide = false;
      }, 1);
    },
  },
  updated() {
    this.locales = this.$i18n.locale;
  },
  //เรียกข้อมูลการตั้งค่าภาษาตอนเปิดหน้าเว็บ
  beforeMount() {
    //เรียกข้อมูลจาก local
    this.locales = localStorage.getItem("language");
    //ถ้าไม่เจอค่า
    if (this.locales == null || this.locales == "") {
      //ตั้งค่าเริ่มต้นเป็นภาษาอังกฤษ
      this.locales = "en";
    }
  },
  mounted() {
    if (
      this.data["strCampusNameEN"] == undefined ||
      this.data["strCampusNameTH"] == undefined
    ) {
      if (this.$refs["intCampusMain"] !== undefined) {
        this.$refs["intCampusMain"][0].changeToggle(false);
        this.$refs["intCampusMain"][0].changeInactive(true);
        this.$refs["intCampusMain"][0].tooltipMessage = "404";
      }
    }
  },
  methods: {
    deleteData() {
      // console.log("click button delete");
      this.$emit("delete");
    },
    componentsInactive(inactive) {
      if (Array.isArray(inactive)) {
        inactive.forEach((element) => {
          // console.log(element.name);
          // console.log(this.$refs[element.name]);
          if (element.inactive !== undefined) {
            this.$refs[element.name][0].inactive = element.inactive;
          } else if (element.tooltip !== undefined) {
            this.$refs[element.name][0].tooltip = element.tooltip;
          } else if (element.blnConfirm !== undefined) {
            this.$refs[element.name][0].blnConfirm = element.blnConfirm;
          } else if (element.strResult !== undefined) {
            this.$refs[element.name][0].strResult = element.strResult;
          } else if (element.enable !== undefined) {
            this.$refs[element.name][0].enable = element.enable;
          } else if (element.enablelist !== undefined) {
            this.$refs[element.name][0].enablelist = element.enablelist;
          } else if (element.showSub !== undefined) {
            this.$refs[element.name][0].showSub = element.showSub;
          } else if (element.strvalidate !== undefined) {
            this.$refs[element.name][0].strvalidate = element.strvalidate;
          } else if (element.listdropdownitem !== undefined) {
            this.$refs[element.name][0].listdropdownitem = element.listdropdownitem;
          }
        });
      }
      return true;
    },

    //select dropdwon
    selecteddropdown(event, index) {
      this.listUserFieldSet[index] = event;
    },
    //ดึงข้อมูล address
    async getUserfieldAddress(index) {
      try {
        //console.log(this.listItemsFieldSet[index] == undefined);
        if (
          this.listItemsFieldSet[index] == undefined ||
          this.listItemsFieldSet[index].length == undefined
        ) {
          //ดึงข้อมูล address
          await this.fetchUserfiledSetAddress().then((data) => {
            this.listItemsFieldSet[index] = { ...data };
          });
        }
      } catch (error) {
        console.log(error);
      }
      //return items;
    },

    //ดึงข้อมูล  address
    async fetchUserfiledSetAddress() {
      // console.log(this.listUserFieldSet);
      try {
        let res = await axios.get(
          urlapi + "/v1/api/AdsUserField/GetAddressList/"
        );

        let data = [];
        data = { ...res.data.data };

        return data;
      } catch (error) {
        console.log(error);
      }
    },

    updateData(state, event, type) {
      // ถ้าเป็นcheckbox จะดึงจะจาก checked
      // console.log(type);
      if (type === "Toggle") {
        // console.log(state);
        // console.log(event.value);
        if (event.value == true) {
          this.$emit("input", { state, value: 1 });
          if (state === "intCampusMain") {
            if (event.value == 1 || event.value == true) {
              // console.log(state);
              this.$refs["intCpstatus"][0].changeInactive(true);
              this.$refs["intCpstatus"][0].changeToggle(true);
            }
          }
          state = "confirm" + state;
          this.$emit("input", { state, value: event.confirm });
        }
        if (event.value == false) {
          if (state === "intCampusMain") {
            if (event.value == 0 || event.value == false) {
              setTimeout(() => {
                // console.log(this.$refs["intCpstatus"]);
                this.$refs["intCpstatus"][0]?.changeInactive(false);
              }, 100);
            }
          }
          this.$emit("input", { state, value: 0 });
          state = "confirm" + state;
          this.$emit("input", { state, value: event.confirm });
        }
      } else if (type == "Dropdown") {
        this.$emit("input", { state, value: event.id });
      } else if (type == "InputFile") {
        this.$emit("input", { state, value: event });
      } else if (type == "Selectdropdown") {
        // console.log(event);
        // console.log(state);
        if (state !== "intSubdistrict") {
          // console.log(event.value);
          if (event == "") {
            this.$emit("input", { state, value: event });
          } else {
            this.$emit("input", { state, value: event.value });
            state = "confirm" + state;
            this.$emit("input", { state, value: event.confirm });
          }

          if (
            this.$refs["intSubdistrict"] != undefined ||
            this.$refs["strSubdistrict"] != undefined
          ) {
            // console.log("value : " + event.value);
            this.$refs["intSubdistrict"][0].strResult = event.value.lang.langTH;
            this.$refs["strSubdistrict"][0].strResult = event.value.lang.langEN;
            this.$emit("input", {
              state: "intSubdistrict",
              value: event.value,
            });
            state = "confirm" + "intSubdistrict";
            this.$emit("input", {
              state: "intSubdistrict",
              value: event.confirm,
            });
          }
        } else if (state === "intSubdistrict" || state === "strSubdistrict") {
          // console.log(event);
          if (event.value != null && event.value !== "") {
            // console.log(this.$refs["strSubdistrict"]);
            this.$refs["strSubdistrict"][0].strResult = event.value.lang.langEN;
            // console.log(this.$refs["strSubdistrict"][0].strResult);
          } else {
            this.$refs["strSubdistrict"][0].strResult = "";
          }

          if (event.value !== undefined) {
            this.$emit("input", { state, value: event.value });
            state = "confirm" + state;
            this.$emit("input", { state, value: event.confirm });
          } else {
            this.$emit("input", { state, value: "" });
          }
        }
      } else if (type === "InputNumber" || type === "InputWorkPeriod") {
        // console.log(event);
        if (event.target !== undefined) {
          this.$emit("input", { state, value: event.target.value });
        } else {
          this.$emit("input", { state, value: event });
        }
      } else if (type == "inputDynamicSizeVue") {
        if (event.target !== undefined) {
          this.$emit("input", { state, value: event.target.value });
        } else {
          this.$emit("input", { state, value: event });
        }
      }
      // ถ้าเป็นinputtext ปกติ จะดึงจะจาก value
      else if (
        type != "Toggle" &&
        type != "Dropdown" &&
        type != "InputNumber" &&
        type != "InputWorkPeriod" &&
        type != "Selectdropdown"
      ) {
        this.$emit("input", { state, value: event.target.value });

        if (state == "strCampusNameEN") {
          localStorage.setItem("strCampusNameEN", event.target.value);
          this.openToggleMainCampus();
        }
        if (state == "strCampusNameTH") {
          localStorage.setItem("strCampusNameTH", event.target.value);
          this.openToggleMainCampus();
        }
      }
    },

    openToggleMainCampus() {
      if (
        (this.data["strCampusNameEN"] !== undefined &&
          this.data["strCampusNameTH"] !== undefined) ||
        (this.data["strCampusNameTH"]?.trim().length > 0 &&
          this.data["strCampusNameEN"]?.trim().length > 0)
      ) {
        this.$refs["intCampusMain"][0].changeToggle(false);
        this.$refs["intCampusMain"][0].changeInactive(false);
        this.$refs["intCampusMain"][0].tooltipMessage = "";
      }

      // console.log(this.data["strCampusNameTH"].trim().length);
      if (
        this.data["strCampusNameEN"] === undefined ||
        this.data["strCampusNameTH"] === undefined ||
        this.data["strCampusNameTH"].trim().length == 0 ||
        this.data["strCampusNameEN"].trim().length == 0
      ) {
        this.$refs["intCampusMain"][0].changeInactive(true);
        this.$refs["intCampusMain"][0].changeToggle(false);
        this.$refs["intCampusMain"][0].tooltipMessage = "404"; //ถ้าไม่ได้กรอกข้อมูลใช้ ID:404
      }
    },

    //ปิดform รับจากปุ่ม close
    closeForm() {
      this.$emit("close", false);
    },
    haveAlertField(blnSubmit) {
      let count = 0;
      for (const strRef in this.$refs) {
        // console.log(this.$refs[strRef][0].alertMsgStatus, strRef);
        if (this.$refs?.[strRef]?.[0]?.alertMsgStatus === true) {
          // console.log(this.$refs[strRef][0].alertMsgStatus, count == 0 , blnSubmit)
          if (count == 0 && blnSubmit) {
            this.scrollIntoView(strRef);
          }
          count++;
        }
      }
      return count > 0;
    },
    haveRequiredEmpty(blnSubmit) {
      var count = 0;
      for (const item of this.request) {
        // console.log(item);
        // console.log(this.new_data[this.request[item]]);
        // console.log(this.$refs.DynamicForm.validate(this.request[item]));
        
        let validate = false;
        if(blnSubmit){
            validate = this.validate(item);
          }
        // console.log(this.$refs.DynamicForm.validate(this.request[item]));
        // console.log(validate);
        if (
          validate == true ||
          this.data[item] === undefined ||
          this.data[item] === null || this.data[item] === 0
        ) {
   
          if (blnSubmit) {
            this.alertRequest(item);
          }
          if(item != 'intAcorgstatus'){
            count += 1;
          }
          
        } else if (this.data[item] !== undefined) {
          if (this.data[item].toString() == "") {
            // console.log(item)
            if (blnSubmit) {
              this.alertRequest(item);
            }
            count += 1;
          }
        } else if (validate == true) {
          if (blnSubmit) {
            this.alertRequest(item);
          }
          count += 1;
        }
      }
      // console.log("Count  :" + count);
      
        return count>0;
      
    },
    //เพิ่มข้อมูล รับจากปุ่ม submit
    addData() {
      this.$emit("post");
    },

    validate(name) {
      if (this.$refs[name] !== undefined) {
        if (this.$refs[name][0] !== undefined) {
          // console.log(name);
          // console.log(this.$refs[name][0]);
          try {
            this.$refs[name][0].Valid();
            return this.$refs[name][0].alertMsgStatus;
          } catch (error) {
            console.log(error);
            return false;
          }
        }
      }
    },

    //เรียก method แจ้งเตือนว่าต้องการข้อมูลสำคัญ โดยอ้างอิงจากชื่อ component ด้วยการใช้ชื่อของตัวแปร
    alertRequest(name) {
      // console.log(name);
      if (this.$refs[name] !== undefined) {
        let mymethod = this.$refs[name][0];

        if (mymethod != null) {
          setTimeout(() => {
            // console.log(name);
            //  console.log(this.$refs[name][0]);
            this.$refs[name][0].alertRequest();
          }, 200);
        }
      }
    },

    alertDataDuplicate(name, msg) {
      this.$refs[name][0].alertDataDuplicate(msg);
    },
    async scrollIntoView(name) {
      const element = this.$refs[name][0].$el;
      // console.log(name);
      // console.log(element.parentElement);
      element.parentElement.scrollIntoView({ block: 'center',behavior: "smooth" });
      //setTimeout(() => {
        //window.scrollBy({ top: -200, behavior: "smooth" });
      //}, 300);
    },
  },
};
</script>

<style scoped></style>
