<template>
  <div
    class="popup-container align-items-center"
    v-show="blnAltHavedata == true"
  >
    <transition name="zoom">
      <AlertPopup
        v-show="blnAltHavedata == true"
        :altMsgBox="$t('216')"
        class="warning"
        classOkBtn="btn-warning text-white"
        classCancelBtn="text-warning"
        artIcon="WarningOutline"
        @confirm="confirmAltPopup($event)"
        classIcon="text-secondary fill-secondary"
        :strButtonSubmit="'218'"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnAltHavedata == true"
        @click="blnAltHavedata = false"
      ></div>
    </transition>
  </div>
  <div
    class="border-radius-lg form"
    :class="device == 'mobile' ? 'p-3' : 'pt-6 pb-4 px-5'"
  >
    <DynamicRenderer
      :request="request"
      :typeSchema="typeSchema"
      :data="new_data"
      @input="updateData($event)"
      @close="closeForm($event)"
      @post="addData()"
      @delete="deleteData()"
      ref="DynamicForm"
      :action="action"
    />
  </div>
</template>

<script>
import DynamicRenderer from "../../components/Renderer/DynamicRenderer.vue";
import AlertPopup from "@/components/alert/alertPopup.vue";
export default {
  components: {
    DynamicRenderer,
    AlertPopup,
  },
  props: {
    styleDlg: { type: String },
    modelTitle: { type: String },
    modelIcon: { type: String },
    idModel: { type: String },
    fontColor: { type: String },
    inactive: {
      type: Array,
    },
    typeSchema: {
      type: Array,
    },

    request: {
      type: Array,
    },
    data: { type: Object },
    action: { type: String },
    msgalertDataDuplicate: { type: Object },
  },
  emits: ["onInput", "Insert", "Update", "delete"],
  data() {
    return {
      overlay: true,
      new_data: { type: Array },
      blnAltHavedata: false,
      updateFormInactive: null,
    };
  },
  created() {
    this.new_data = { ...this.data };
    // setTimeout(() => {
    //   this.new_data = this.data;
    // }, 500);
  },
  unmounted() {
    clearInterval(this.updateFormInactive);
  },
  mounted() {
    this.updateFormInactive = setInterval(() => {
      if (this.inactive !== undefined && this.inactive !== null) {
        setTimeout(() => {
          this.$refs.DynamicForm?.componentsInactive(this.inactive);
        }, 2);
      }
    }, 500);
  },
  methods: {
    deleteData() {
      // console.log("click button delete");
      this.$emit("delete");
    },
    updateData({ state, value }) {
      this.new_data[state] = value;

      // ปิด error ที่อยู่ (EN) ของ Campus
      if (state == "intSubdistrict" && value != "") {
        this.$refs.DynamicForm.$refs["strSubdistrict"][0].error = false;
      }
    },

    async addData() {
      const blnRequiredAlert = await this.$refs.DynamicForm.haveRequiredEmpty(
        true
      );
      const blnFieldsAlert = await this.$refs.DynamicForm.haveAlertField(true);
      const blnError = blnRequiredAlert || blnFieldsAlert;
      setTimeout(() => {
        if (this.action == "Insert") {
          if (blnError != true) {
            this.$emit("Insert", this.new_data);
          }
        } else if (this.action == "Update") {
          if (blnError != true) {
            this.$emit("Update", this.new_data);
          }
        }
      }, 500);
    },
    //ปิดform รับจากปุ่ม close
    async closeForm() {
      this.blnAltHavedata = false;
      let new_dataDummy = { ...this.new_data };
      if (
        this.action != "Update" &&
        new_dataDummy.confirmintCampusMain != undefined
      ) {
        // campus
        delete new_dataDummy.confirmintCampusMain;
        delete new_dataDummy.confirmintCpstatus;
        delete new_dataDummy.confirmintSubdistrict;
        delete new_dataDummy.confirmintAgencyStatus;
        if (new_dataDummy.intCampusMain == 0) {
          delete new_dataDummy.intCampusMain;
        }
        if (new_dataDummy.intCpstatus == 0) {
          delete new_dataDummy.intCpstatus;
        }
      } else if (
        this.action != "Update" &&
        new_dataDummy.confirmintAgencyStatus != undefined
      ) {
        // Agency
        delete new_dataDummy.confirmintAgencyStatus;
        delete new_dataDummy.confirmintCpstatus;
        delete new_dataDummy.confirmintSubdistrict;
        delete new_dataDummy.confirmintAgencyStatus;
        if (new_dataDummy.intAgencyStatus == 0) {
          delete new_dataDummy.intAgencyStatus;
        }
      } else if (new_dataDummy.intSubdistrict != undefined) {
        // Agency
        new_dataDummy.intSubdistrict =
          this.new_data?.intSubdistrict?.id?.intSubdistrictID;
        delete new_dataDummy.confirmintCampusMain;
        delete new_dataDummy.confirmintCpstatus;
        delete new_dataDummy.confirmintSubdistrict;
        delete new_dataDummy.confirmintAgencyStatus;
      } else if (new_dataDummy.confirmintWorkTypeID != undefined) {
        // Position
        delete new_dataDummy.confirmintWorkTypeID;
        delete new_dataDummy.confirmintPositionStatus;
      } else if (new_dataDummy.confirmintPositionStatus != undefined) {
        // Position
        delete new_dataDummy.confirmintPositionStatus;
        delete new_dataDummy.confirmintWorkTypeID;
        if (this.action != "Update" && new_dataDummy.intPositionStatus == 0) {
          delete new_dataDummy.intPositionStatus;
        }
        if (new_dataDummy?.strPositionNameEN == "") {
          delete new_dataDummy.strPositionNameEN;
        }
        if (new_dataDummy?.strPositionNameTH == "") {
          delete new_dataDummy.strPositionNameTH;
        }
        if (
          new_dataDummy?.strPositionWorkPeriod?.day == "0" &&
          new_dataDummy?.strPositionWorkPeriod?.month == "0" &&
          new_dataDummy?.strPositionWorkPeriod?.year == "0"
        ) {
          delete new_dataDummy.strPositionWorkPeriod;
        }
      } else if (new_dataDummy.confirmintAcorgstatus != undefined) {
        // Academic
        delete new_dataDummy.confirmintAcorgstatus;
      } else if (new_dataDummy.confirmintEducationStatus != undefined) {
        // Education
        delete new_dataDummy.confirmintEducationStatus;
      } else if (new_dataDummy.confirmintUsergroupID != undefined) {
        delete new_dataDummy.confirmintUsergroupID;
      }
      // Education
      if (this.action != "Update" && new_dataDummy.intEducationStatus == 0) {
        delete new_dataDummy.intEducationStatus;
      }
      if (new_dataDummy["intAcorgstatus"] == undefined) {
        // Academic
        new_dataDummy["intAcorgstatus"] = "";
      } 
      // Usertype
      // Prefix
      if (this.action != "Update") {
        Object.keys(new_dataDummy).forEach((key) => {
          // console.log(typeof new_dataDummy[key]);
          if (typeof new_dataDummy[key] == "string") {
            if (new_dataDummy[key] == "" && key != 'intAcorgstatus') {
              delete new_dataDummy[key];
            }
          }
        });
      }
      if(this.data['intAcorgstatus'] == undefined){
        delete new_dataDummy['intAcorgstatus'];
      }
      let isEqual = await (JSON.stringify(new_dataDummy) ==
        JSON.stringify(this.data));
      // console.log(isEqual);
      // console.log(this.new_data);
      // console.log(new_dataDummy);
      // console.log(this.data);

      if (this.new_data != null && isEqual == false) {
        for (var item in this.new_data) {
          if (this.new_data[item] !== undefined) {
            this.overlay = true;
            this.blnAltHavedata = true;
          }
        }
      }
      if (this.blnAltHavedata == false || isEqual == true) {
        this.$emit("onInput", false);
      }
    },

    confirmAltPopup(state) {
      // console.log(state);
      if (state == false) {
        this.blnAltHavedata = false;
      } else if (state == true) {
        this.blnAltHavedata = false;
        this.$emit("onInput", false);
        // console.log(state);
      }
    },
    async detectDatainField() {
      var count = 0;
      for (let item of this.request) {
        
        // console.log(this.new_data[this.request[item]]);
        // console.log(this.$refs.DynamicForm.validate(this.request[item]));
        var validate = this.$refs.DynamicForm.validate(item);
        // console.log(this.$refs.DynamicForm.validate(this.request[item]));
        if (
          validate == true ||
          this.new_data[item] === undefined ||
          this.new_data[item] === null ||
          this.new_data[item] === 0
        ) {
          // console.log(item);
          await this.$refs.DynamicForm.alertRequest(item);
          count += 1;
        } else if (this.new_data[item] !== undefined) {
          if (this.new_data[item].toString() == "") {
            await this.$refs.DynamicForm.alertRequest(item);
            count += 1;
          }
        } else if (validate == true) {
          await this.$refs.DynamicForm.alertRequest(item);
          count += 1;
        }
      }
      // console.log("Count  :" + count);
      if (count > 0) {
        return false;
      } else if (count == 0) {
        return true;
      }
    },

    detectalertDataDuplicate(msgalertDataDuplicate) {
      // console.log(msgalertDataDuplicate);
      if (Array.isArray(msgalertDataDuplicate)) {
        for (var item in msgalertDataDuplicate) {
          this.$refs.DynamicForm.alertDataDuplicate(
            msgalertDataDuplicate[item].name,
            msgalertDataDuplicate[item].msg
          );
        }
      }
      this.$refs.DynamicForm.haveAlertField(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 150ms ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.zoom-enter-from,
.zoom-leave-to {
  opacity: 0;
}
.zoom-enter-active {
  animation: bounce-in 150ms;
}
.zoom-leave-active {
  opacity: 0;
}
@keyframes bounce-in {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
.popup-container {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: start;
  justify-content: center;
  .overlay {
    background-color: rgba(0, 0, 0, 0.263);
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
.admin-form-campus {
  max-width: 809px !important;
}
.form {
  position: relative;
}
</style>
