<template>
  <div class="mb-3">
    <label class="txt-inp">
      <input
        required
        type="text"
        maxlength="10"
        minlength="10"
        :name="name"
        :id="name"
        :value="value"
        v-on:keypress="isPhone($event)"
      />

      <p class="label-text">{{ $t(label) }}</p>
      <div
        v-if="!isPhone()"
        class="d-flex alertInput align-items-center p-0 mt-1"
      >
        <Icon icon="carbon:warning" class="me-1" />
        {{ alertMsg }}
      </div>
    </label>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import Validation from "../../typescript/validator/validator";
export default {
  props: {
    name: { type: String },
    label: { type: String },
    value: { type: String },
    validate: { type: String },
    maxlength: { type: String, required: false },
    hint: {
      type: String,
      default: "input",
    },
    alertMsg: { type: String },
  },
  components: {
    Icon,
  },

  methods: {
    isPhone: function (e) {
      if (this.value != null) {
        const char = String.fromCharCode(e.keyCode);
        this.alertMsgStatus = Validation[this.validate](this.value, char);
        // console.log(this.alertMsgStatus);
        if (this.alertMsgStatus == true) {
          e.preventDefault();
        }
        // if (/^0[1-9]{1}[0-9]{8}$/.test(this.value)) {
        //   this.alertMsgStatus = false;
        //   return true;
        // } else {
        //   this.alertMsgStatus = true;
        //   return false;
        // }
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
