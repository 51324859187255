export const typeSchema = [
  {
    // class: "ms-5",
    group: [
      {
        formID: "Campus",
        group: [
          {
            class: "row m-0 gx-3 p-0",
            group: [
              {
                group: [
                  {
                    class: "mb-4 mx-0 p-0",
                    component: [
                      {
                        label: "2", //Campus
                        type: "HeadLabel",
                        class: "fnt-medium fnt-subtitle",
                      },
                    ],
                  },
                ]
              },
              {
                group: [
                  {
                    class: "mb-4 bg-black-200 p-3 rounded-2 mx-0 p-0",
                    component: [
                      {
                        label: "",
                        type: "HeadLabel",
                        class: "fnt-regular fnt-body",
                        datavalue: "strCampusNameTHLabel"
                      },
                      {
                        label: "",
                        type: "HeadLabel",
                        class: "fnt-regular fnt-body",
                        datavalue: "strCampusNameENLabel"
                      },
                    ],
                  },
                ]
              },
              // {
              //   group: [
              //     {
              //       class: "mb-4 mx-0 p-0",
              //       component: [
              //         {
              //           label: "3", //Department information
              //           type: "HeadLabel",
              //           class: "fnt-medium fnt-subtitle",
              //         },
              //       ],
              //     },
              //   ]
              // },
              {
                group: [
                  {
                    class: "mb-3 mx-0 p-0",
                    component: [
                      {
                        label: "3",
                        type: "DepartmentRoot",
                        datavalue: "Rootname",

                      }
                    ]
                  }
                ]
              },
              // {
              //   class: "row mb-3 gx-3",
              //   group: [
              //     {
              //       class: "col-lg-8 mb-3 pe-lg-2 p-sm-0 col-sm-12",
              //       component: [
              //         {
              //           label: "233", // Campus name EN
              //           type: "InputText",
              //           datavalue: "strCampusNameEN",
              //           name: "inpCampusnameEN",
              //           showTxtInp: false,
              //         },
              //       ],
              //     }
              //   ]
              // },
              {
                class: "d-flex flex-row align-items-start mb-3",

                group: [
                  {
                    class: "d-flex justify-content-between w-100 mb-4 p-0",
                    component: [
                      {
                        name: "417", //Department
                        label: "201", //{Department} information
                        type: "HeadLabel",
                        class: "fnt-medium fnt-subtitle",
                      },
                      {
                        label: "34", // Require
                        type: "HeadLabel",
                        class: "text-danger-500 fnt-regular fnt-caption",
                      },
                    ],
                  },
                  // -----
                  {
                    class: "col-lg-8 mb-3 pe-lg-2 p-sm-0 p-0 col-sm-12",

                    component: [
                      {
                        label: "160", // Department name (TH)
                        type: "InputText",
                        datavalue: "strAgencyNameTH",
                        alertMsg: "202",
                        hint: "Please input in Thai",
                        name: "inpAgencynameTH",
                        validate: "letterTH",
                        request: true,
                        maxlength: 200,
                      },
                    ],
                  },
                  {
                    class: "col-lg-4 mb-3 ps-lg-2 p-sm-0 p-0 col-sm-12",
                    component: [
                      {
                        label: "161", // Department initial (TH)
                        type: "InputText",
                        datavalue: "strAgencyInitialTH",
                        alertMsg: "202",
                        hint: "Please input in Thai",
                        name: "inpAgencyinitialTH",
                        validate: "InitialTH",
                        request: true,
                        maxlength: 20,
                      },
                    ],
                  },
                ],
              },

              {
                class: "row mb-3 gx-3",
                group: [
                  {
                    class: "col-lg-8 mb-3 pe-lg-2 p-sm-0 p-0 col-sm-12",
                    component: [
                      {
                        label: "157", // Department name
                        type: "InputText",
                        datavalue: "strAgencyNameEN",
                        alertMsg: "202",
                        hint: "Entered Data is Incorrect",
                        name: "inpAgencynameEN",
                        validate: "letterEN",
                        request: true,
                        maxlength: 200,
                      },
                    ],
                  },
                  {
                    class: "col-sm-12 col-lg-4 mb-3 ps-lg-2 p-sm-0 p-0",

                    component: [
                      {
                        label: "158", // Department initial
                        type: "InputText",
                        datavalue: "strAgencyInitialEN",
                        alertMsg: "202",
                        hint: "Entered Data is Incorrect",
                        name: "inpAgencyinitialEN",
                        validate: "InitialEN",
                        request: true,
                        maxlength: 20,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            class: "row m-0",
            group: [
              {
                class: "row mb-4 gx-3",
                group: [
                  {
                    class: "d-flex justify-content-between p-0",
                    component: [
                      {
                        label: "32", //Contact
                        type: "HeadLabel",
                        class: "fnt-medium fnt-subtitle mb-4",
                      },
                    ],
                  },
                ],
              },
              {
                class: "row mb-3 gx-3",
                group: [
                  {
                    class: "col-lg-4 col-sm-4 p-0",
                    component: [
                      {
                        type: "InputPrefix",
                        label: "364",
                        request: true,
                        alertMsg: "202",
                        datavalue: "strCountrycode",
                        validate: "countrycode",
                        maxlength: 20,
                      },
                    ],
                  },

                  {
                    class: "col-lg-10 col-sm-12 mb-3 pe-lg-2 p-sm-0 p-0",
                  },
                ],
              },
              {
                class: "row mb-3 gx-3",
                group: [
                  {
                    class: "col-lg-6 col-sm-12 mb-2 p-0 d-flex justify-content-end", // Label phone ex.
                    component: [
                      {
                        type: "HeadLabel",
                        label: "372",
                        helper: true,
                        class: "fnt-regular fnt-body",
                        tooltipMsg: "375",
                        classBtn: "tooltiper",
                        classBtnIcon:'fill-black',
                        icon: "QuestionOutline",
                        tooltipPlacement: "tooltip-right"
                      },
                    ],
                  },
                  {
                    class: "col-lg-6 col-sm-12 mb-2 p-0 d-sm-none d-lg-flex",
                  },
                  {
                    class: "col-lg-6 col-sm-12 mb-3 pe-lg-2  p-sm-0 p-0",
                    component: [
                      {
                        type: "InputLongText",
                        label: "365", // Dis
                        require: " *",
                        datavalue: "strAgencyTelTH",
                        validate: "PhoneNumberTH",
                        alertMsg: "202",
                        request: true,
                        maxlength: 200,
                      },
                    ],
                  },
                  {
                    class: "col-lg-6 col-sm-12 ps-lg-2  p-sm-0 p-0",
                    component: [
                      {
                        type: "InputLongText",
                        label: "366",
                        require: " *",
                        datavalue: "strAgencyTelEN",
                        validate: "PhoneNumberEN",
                        alertMsg: "202",
                        request: true,
                        maxlength: 200,
                      },
                    ],
                  },
                  {
                    class: "col-lg-6 col-sm-12 mb-2 p-0 d-flex justify-content-end", // Label Fax ex.
                    component: [
                      {
                        type: "HeadLabel",
                        // name:"Example of filling in fax information.",
                        label: "373",
                        helper: true,
                        class: "fnt-regular fnt-body",
                        tooltipMsg: "375",
                        classBtn: "tooltiper",
                        classBtnIcon:'fill-black',
                        icon: "QuestionOutline",
                        tooltipPlacement: "tooltip-right"
                      },
                    ],
                  },
                  {
                    class: "col-lg-6 col-sm-12 mb-2 p-0 d-sm-none d-lg-flex",
                  },
                  {
                    class: "col-lg-6 col-sm-12 mb-3 pe-lg-2  p-sm-0 p-0",
                    component: [
                      {
                        type: "InputLongText",
                        label: "367",
                        datavalue: "strAgencyFaxTH",
                        validate: "letterTH",
                        alertMsg: "202",
                        request: true,
                        maxlength: 200,
                      },
                    ],
                  },
                  {
                    class: "col-lg-6 col-sm-12 ps-lg-2 mb-3 p-sm-0 p-0",
                    component: [
                      {
                        type: "InputLongText",
                        label: "368",
                        datavalue: "strAgencyFaxEN",
                        validate: "letterEN",
                        alertMsg: "202",
                        request: true,
                        maxlength: 200,
                      },
                    ],
                  },
                ],
              },
              {
                class: "row  mb-3 gx-3",
                group: [
                  {
                    class: "col-lg-6 col-sm-12 mb-3 pe-lg-2 p-sm-0 p-0",
                    component: [
                      {
                        label: "71", // Department Facebook
                        type: "InputText",
                        datavalue: "strAgencyFacebook",
                        alertMsg: "202",
                        hint: "e.g. https://www.facebook.com/ex/",
                        name: "inpAgencyFacebook",
                        validate: "facebookpage",
                        labelSub: ": https://www.facebook.com/ex/",
                        request: true,
                        maxlength: 200,
                      },
                    ],
                  },
                  {
                    class: "col-lg-6 col-sm-12 p-sm-0 mb-3 ps-lg-2 p-0",
                    component: [
                      {
                        label: "70", // Department Email
                        type: "InputText",
                        datavalue: "strAgencyEmail",
                        alertMsg: "202",
                        hint: "Email: name@email.com",
                        name: "inpAgencyEmail",
                        validate: "email",
                        labelSub: ": name@email.com",
                        request: true,
                        maxlength: 100,
                      },
                    ],
                  },
                ],
              },
              {
                class: "row mb-3 gx-3",
                group: [
                  {
                    class: "col-12 mb-3 px-sm-0 px-md-0 p-0",
                    component: [
                      {
                        label: "73", // Department Website
                        type: "InputText",
                        datavalue: "strAgencyWebsite",
                        alertMsg: "202",
                        hint: "e.g. https://www.wu.ac.th/",
                        name: "inpAgencyWebsite",
                        labelSub: ": https://www.wu.ac.th/",
                        request: true,
                        validate: "webUrl",
                        maxlength: 200,
                      },
                    ],
                  },
                ],
              },
              // Toggle
            ],
          },

          {
            class: "row m-0",
            group: [
              {
                class: "my-4",
                group: [
                  {
                    class: "p-0",
                    component: [
                      {
                        label: "389", //Contact
                        type: "HeadLabel",
                        class: "fnt-medium fnt-subtitle mb-3",
                      },
                    ],
                  },
                ],
              },
              {
                class: "row gx-3",
                group: [
                  {
                    class: "col-12 mb-3 pe-lg-2 p-sm-0 p-0",
                    component: [
                      {
                        label: "369", // Address number
                        type: "InputText",
                        datavalue: "strAgencyAddressTH",
                        alertMsg: "202",
                        hint: "Please input in Thai",
                        name: "inpAddressnumberTH",
                        // validate: "addressTH",
                        validate: "",//พี่บอล&พี่ปามให้ปล่อยฟรี
                        request: true,
                        maxlength: 1000,
                      },
                    ],
                  },
                  {
                    class: "col-12 mb-3 mb-3 pe-lg-2 p-sm-0 p-0",
                    component: [
                      {
                        type: "Selectdropdown",
                        strName: "376", // Provice
                        star: " *",
                        state: "address",
                        List: true,
                        alertMsg: "202",
                        blnSystemword: false,
                        defaultLang: "TH",
                        datavalue: "intSubdistrict",
                        blnAddress: true,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            class: "row m-0",
            group: [
              {
                class: "my-4",
                group: [
                  {
                    class: "p-0",
                    component: [
                      {
                        label: "390", //Contact
                        type: "HeadLabel",
                        class: "fnt-medium fnt-subtitle mb-3",
                      },
                    ],
                  },
                ],
              },
              {
                class: "row mb-3 gx-3",
                group: [
                  {
                    class: "col-12 mb-3 pe-lg-2 p-sm-0 p-0",
                    component: [
                      {
                        label: "370", // Address number
                        type: "InputText",
                        datavalue: "strAgencyAddressEN",
                        alertMsg: "202",
                        hint: "Please input in Thai",
                        name: "inpAddressnumberEN",
                        // validate: "addressEN",
                        validate: "",//พี่บอล&พี่ปามให้ปล่อยฟรี
                        request: true,
                        maxlength: 1000,
                      },
                    ],
                  },
                  {
                    class: "col-12 mb-3 mb-3 pe-lg-2 p-sm-0 p-0",
                    component: [
                      {
                        type: "Selectdropdown",
                        strName: "377", // Provice
                        star: " *",
                        state: "address",
                        List: true,
                        alertMsg: "202",
                        blnSystemword: false,
                        defaultLang: "TH",
                        datavalue: "strSubdistrict",
                        blnAddress: true,
                      },
                    ],
                  },
                  // {
                  //   class: "col-12 mb-3 mb-3 pe-lg-2 p-sm-0",
                  //   component: [
                  //     {
                  //       type: "Selectdropdown",
                  //       strName: "382", // Provice
                  //       star: " *",
                  //       state: "address",
                  //       List: true,
                  //       alertMsg: "202",
                  //       blnSystemword: false,
                  //       defaultLang: "EN",
                  //       datavalue: "intSubdistrict",
                  //     },
                  //   ],
                  // },
                ],
              },
            ],
          },
          {
            class: "d-flex flex-column gx-3 ",
            group: [
              {
                class: "mb-lg-3 mb-sm-2 p-0",
                component: [
                  {
                    name: "19", // Status
                    type: "Toggle",
                    open: "61", // Open
                    close: "62", // Close
                    datavalue: "intAgencyStatus",
                    alertMsg: "407",
                    altMsgBox: "407",
                    tooltipMsg: "402",
                    tooltipPlacement: "tooltip-top",
                    // sub: "416"
                  },
                ],
              },
            ],
          },
          {
            class: "row mb-lg-3 mb-sm-2 ps-3",
            group: [
              {
                class: "p-0",
                component: [
                  {
                    type: "HeadLabel",
                    require: " *",
                    label: "33", // Logo
                    class: "mb-3 p-0 fnt-subtitle fnt-medium",
                  },
                ],
              },
              {
                class: "col-12 mx-0",
                component: [
                  {
                    name: "33", // Logo
                    type: "InputFile",
                    datavalue: "strPicpath",
                    alertMsg: "202",
                    helper: "374",
                    maxSize: 2000000,
                    subname: "405",
                    widthicon: "32",
                    heighticon: "32",
                    filetype: "image/jpeg,image/png"
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    group: [
      {
        class: "d-flex justify-content-between mt-6",
        group: [
          {
            class: "d-flex",
            group: [
              {
                class: "modal-footer border-0 justify-content-start p-0 ",
                component: [
                  {
                    type: "Button",
                    name: "36", // OK
                    state: "submit",
                    class: "btn-fix  me-3",
                    deleteinform: false,
                  },
                  {
                    type: "Button",
                    name: "35", // Cancel
                    state: "cancel",
                    class: "btn-fix btn-cancel",
                    deleteinform: false,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        class: "w-100 d-flex justify-content-center",
        group: [
          {
            component: [
              {
                class: "mt-3",
                type: "DeleteinForm",
                icon: "DeleteFill",
                classBtnIcon: "fill-secondary",
                datavalue: "blnDeleteinForm",
                state: "delete",
              },
            ],
          },
        ],
      },
    ],
  },
];
export const request = [
  "strAgencyNameEN",
  "strAgencyInitialEN",
  "intSubdistrict",
  "strAgencyNameTH",
  "strAgencyInitialTH",
  "strAgencyAddressEN",
  "strAgencyAddressTH",
  "strAgencyEmail",
  "strAgencyFacebook",

  "strAgencyWebsite",
  "strPicpath",

  "strAgencyTelTH",
  "strAgencyTelEN",

  "strCountrycode",
];
