const apiagency = {
  insertAgency: "/v1/api/AdsAgency/InsertAgency?MemberID=",
  updateAgency: "/v1/api/AdsAgency/UpdateAgency/",
  deleteAgency: "/v1/api/AdsAgency/DeleteAgency/",
  getAgencyList: "/v1/api/AdsAgency/AgencyList/",
  getAgencyByID: "/v1/api/AdsAgency/AgencyByID/",
  duplicate: "/v1/api/AdsAgency/CheckduplicateAgency",
  checkdDeleteAgency: "/v1/api/AdsAgency/CheckDeleteAgency/",
  checkUsedAgency: "/v1/api/AdsAgency/CheckUsedAgecny/",
  getAgencyRoot:"/v1/api/AdsAgency/GetAgencyRoot/"
};

export default apiagency;
