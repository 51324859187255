<template>
  <div class="mb-3">
    <label class="txt-inp">
      <input
        type="text"
        required
        :maxlength="maxlength"
        :name="name"
        :id="name"
        :value="value"
        v-on:keypress="isFacebook()"
      />
      <p>{{ $t(label) }}</p>
    </label>
    <div
      v-if="alertMsgStatus == true"
      class="d-flex alertInput align-items-center p-0 mt-1"
    >
      <Icon icon="carbon:warning" class="me-1" />
      {{ alertMsg }}
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import { Icon } from "@iconify/vue";

export default {
  data() {
    return {
      alertMsgStatus: false,
    };
  },

  components: {
    Icon,
  },
  //props: ["label", "value","name","lang","maxlength"],
  props: {
    name: { type: String },
    // valueName: { type: String, required: true },
    lang: { type: String, default: "EN" },
    label: { type: String },
    value: { type: String },
    maxlength: { type: String },
    hint: {
      type: String,
      default: "input",
    },
    alertMsg: { type: String },
  },
  methods: {
    isFacebook: function () {
      if (this.value != null) {
        if (/^(https?:\/\/)?((w{3}\.)?)facebook.com\/./i.test(this.value)) {
          this.alertMsgStatus = false;
          return false;
        } else {
          this.alertMsgStatus = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.alertInput {
  font-size: 12px;
}
.txt-inp {
  height: 48px;
  position: relative;
  width: 100%;
  p {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s;
    padding: 0 4px;
    font-size: 16px;
    background: none;
    color: #8e8e8e;
    cursor: text;
  }
  input {
    height: 100%;
    outline: 0;
    font-size: 16px;
    padding-left: 8px;
    border: solid 1px #cacaca;
    width: 100%;
    border-radius: 4px;
    transition: all 0.2s;
    &:focus {
      border: solid 1px $primary;
      & + p {
        top: 7%;
        color: $primary;
        transition: all 0.2s;
        background-color: #fff;
        font-size: 14px;
      }
    }
    &:valid + p {
      color: $primary;
      transition: all 0.2s;
      background-color: #fff;
      font-size: 14px;
      top: 7%;
    }
  }
}
</style>
