<template>
  <div v-if="inactive == false">
    <label class="txt-inp" :class="size || device == 'mobile' ? 'w-100':'w-75'" >
      <AddOutline class="fill-black-500 icon-prefix" width="24" height="24" />
      <input
        type="text"
        required
        :maxlength="maxlength"
        :name="name"
        :id="name"
        :value="value"
        @keypress="isLetter($event)"
        @keyup.delete="deleteLetter()"
        @change="Valid()"
        :class="alertMsgStatus == true ? 'invalid' : '' + size"
        class="w-100"
        title=""
        :placeholder="$t(placeholder)"
      />
      <p class="label-text bg-white" :class="device == 'mobile' ? 'fnt-caption':''">
        {{ ValidNumber(label) ? label : $t(label) }}
        <span class="sub">{{ labelSub }}</span>
        <span class="require ms-1" v-if="request === true">*</span>
      </p>
    </label>
    <div
      :class="alertMsgStatus == true ? '' : 'd-none'"
      class="d-flex alertInput align-items-center p-0 mt-1 fnt-body fnt-regular"
    >
      <Icon
        :class="alertMsgStatus == true ? '' : 'd-none'"
        icon="carbon:warning"
        class="me-1"
      />
      {{ $t(alertMsgInput) }}
    </div>
  </div>

  <div class="txt-inp disable-inp" v-else-if="inactive == true">
    <label class="txt-inp">
      <AddOutline class="fill-black-500" width="24" height="24" />
      <input
        type="text"
        required
        :maxlength="maxlength"
        :name="name"
        :id="name"
        :value="value"
        @keypress="isLetter($event)"
        @change="Valid()"
        :class="size"
        class="w-100"
        title=""
        disabled
        :placeholder="$t(placeholder)"
      />
      <p
        class="label-text rounded-2 bg-white"
        :class="device == 'mobile' ? 'fnt-caption' : ''"
      >
        {{ ValidNumber(label) ? label : $t(label)
        }}<span class="sub">{{ labelSub }}</span>
        <span class="require" v-if="request === true"> *</span>
      </p>
    </label>
  </div>
  <!-- </div> -->
</template>

<script>
import { AddOutline } from "@/components/icon/index";
import { Icon } from "@iconify/vue";
import Validation from "../../typescript/validator/validator";
export default {
  data() {
    return {
      alertMsgStatus: false,
      alertMsgInput: "",
      inactive: false,
    };
  },

  components: {
    Icon,
    AddOutline,
  },

  props: {
    request: { type: Boolean, default: false },
    name: { type: String },
    label: { type: String },
    value: { type: String },
    statename: { type: String },
    maxlength: { type: String },
    validate: { type: String, default: "" },
    labelSub: { type: String }, // เป็น Label เสริมเมื่อทำการ Select จะหายไป
    hint: {
      type: String,
      default: "input",
    },
    alertMsg: { type: String },
    showTxtInp: { type: Boolean, default: false }, // Input disabled
    size: { type: String },
    placeholder: { type: String, default: "" },
  },
  unmounted() {
    document.removeEventListener("click", this.close);
  },
  mounted() {
    document.addEventListener("click", this.close);
    if (this.inactive !== true) {
      this.inactive = this.showTxtInp;
    }
  },
  methods: {
    ValidNumber(value) {
      return isNaN(value);
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        if (this.alertMsgStatus == true) {
          this.Valid();
        }
      }
    },
    deleteLetter() {
      // console.log("Valid");
      this.alertMsgStatus = false;
    },
    alertRequest() {
      this.alertMsgInput = this.$t(this.alertMsg, {
        fieldname: this.$t(this.label).toLowerCase(),
      });
      // console.log(this.alertMsgInput);
      this.alertMsgStatus = true;
      // console.log(this.alertMsgStatus);
    },
    alertDataDuplicate(msg) {
      this.alertMsgInput = this.$t(msg, {
        fieldname: this.$t(this.label).toLowerCase(),
      });
      this.alertMsgStatus = true;
    },
    Valid() {
      this.alertMsgStatus = false;
      if (this.value === "" || this.value === undefined) {
        return;
      } else {
        if (this.validate == "email") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "57";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "number") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "40";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "currency") {
          return Validation[this.validate](this.value);
        } else {
          if (this.validate != "") {
            this.alertMsgStatus = !Validation[this.validate](this.value);
            if (this.alertMsgStatus == true) {
              this.alertMsgInput = this.$t("202", {
                fieldname: this.$t(this.label).toLowerCase(),
              });
              return true;
            } else if (this.alertMsgStatus == false) {
              return Validation[this.validate](this.value);
            }
          }
        }
      }
    },
    isLetter(e) {
      this.alertMsgStatus = false;
      if (this.validate != null) {
        let char = String.fromCharCode(e.keyCode);
        if (this.validate == "letterEN" || this.validate == "letterTH") {
          if (this.validate == "letterEN") {
            this.alertMsgStatus = !Validation[this.validate](char);
            if (this.alertMsgStatus == true) {
              this.alertMsgInput = "46";
              e.preventDefault();
              return true;
            } else if (this.alertMsgStatus == false) {
              return Validation[this.validate](char);
            }
          } else if (this.validate == "letterTH") {
            this.alertMsgStatus = !/^[ก-๏.\s\d 0-9๐-๙]+$/.test(char);
            if (this.alertMsgStatus == true) {
              this.alertMsgInput = "45";
              e.preventDefault();
              return true;
            } else if (this.alertMsgStatus == false) {
              return Validation[this.validate](char);
            }
          }
        } else if (this.validate == "InitialEN") {
          if (/^[A-Za-z.]+$/.test(char)) {
            this.alertMsgStatus = false;
          } else {
            this.alertMsgStatus = true;
            this.alertMsgInput = "231";
            e.preventDefault();
          }
        } else if (this.validate == "InitialTH") {
          if (/^[ก-๏.]+$/.test(char)) {
            this.alertMsgStatus = false;
          } else {
            this.alertMsgStatus = true;
            this.alertMsgInput = "232";
            e.preventDefault();
          }
        } else if (this.validate == "email") {
          if (/^[A-Za-z\s\d@.]+$/.test(char)) {
            this.alertMsgStatus = false;
          } else {
            this.alertMsgStatus = true;
            this.alertMsgInput = "57";
            e.preventDefault();
          }
        } else if (this.validate == "number") {
          if (/^[0-9]+$/.test(char)) {
            this.alertMsgStatus = false;
            return false;
          } else {
            this.alertMsgInput = "56";
            this.alertMsgStatus = true;
            e.preventDefault();
            return true;
          }
        } else if (this.validate == "currency") {
          if (/^[0-9.,]+$/.test(char)) {
            this.alertMsgStatus = false;
            return false;
          } else {
            this.alertMsgInput = '56';
            this.alertMsgStatus = true;
            e.preventDefault();
            return true;
          }
        } else if (this.validate == "countrycode") {
          if (/^[0-9-]+$/.test(char)) {
            this.alertMsgStatus = false;
            return false;
          } else {
            this.alertMsgInput = '56';
            this.alertMsgStatus = true;
            e.preventDefault();
            return true;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.medium {
  height: 38px !important;
  .label-text {
    font-size: 14px !important;
    padding: 0 4px !important;
  }
  input {
    outline: 0;
    font-size: 14px !important;
    padding: 10px 16px !important;
    border: solid 1px #cacaca;
    width: 100%;
    border-radius: 4px;
    transition: all 0.2s;
    overflow: hidden;
    &::placeholder {
      font-weight: bold;
      opacity: 0.5;
      color: red;
    }
    &:focus {
      border: solid 1px $primary;
      & + p {
        top: 0% !important;
        color: $primary;
        transition: all 0.2s;
        background-color: #fff;
        font-size: 12px !important;
        .sub {
          display: none !important;
        }
        .require {
          display: flex !important;
        }
      }
    }
    &:valid + p {
      color: $primary;
      transition: all 0.2s;
      background-color: #fff;
      font-size: 12px !important;
      top: 0% !important;
      .sub {
        display: none !important;
      }
      .require {
        display: flex !important;
      }
    }
    &:valid {
      border: solid 1px $primary;
    }
  }
}
.disable-inp {
  input {
    padding: 10px 16px !important;
  }
  .label-text {
    top: -6% !important;
    background-color: $black-200;
  }
  input:disabled {
    background-color: $black-200;
    color: $black;
    border: solid 1px $black-400;
  }
}
.txt-inp {
  position: relative;
  width: 100%;
  .icon-prefix {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
  p {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s;
    padding: 0 4px;
    // font-size: 16px;
    background: none;
    color: $black-500;
    cursor: text;
    display: flex;
    user-select: none;
  }

  input {
    outline: 0;
    // font-size: 16px;
    padding-top: 10px !important;
    padding-left: 32px !important;
    padding-bottom: 10px !important;
    border: solid 1px $black-500;
    width: 100%;
    border-radius: 4px;
    transition: all 0.2s;
    overflow: hidden;
    // padding-top: 4px;
    &::placeholder {
      color: $white;
    }
    &:hover {
      border: solid 1px $primary;

      & + p {
        color: $primary;
      }
    }

    &:focus {
      border: solid 1px $primary;

      & + p {
        top: -4%;
        color: $primary;
        transition: all 0.2s;
        background-color: #fff;
        font-size: 14px;

        .sub {
          display: none !important;
        }
        .require {
          display: flex !important;
        }
      }
      &::placeholder {
        color: $black-500 !important;
      }
    }

    &:valid + p {
      // color: $primary;
      transition: all 0.2s;
      background-color: #fff;
      font-size: 14px;
      top: -4%;

      .sub {
        display: none !important;
      }
      .require {
        display: flex !important;
      }
    }
  }
}
</style>
