const Group = {
  unflatten: function (arr: string | any[], parent: string) {
    // console.log(arr);
    // console.log(Object.values(arr).length);
    const tree = [];
    const mappedArr = [];
    let arrElem, mappedElem;

    // First map the nodes of the array to an object -> create a hash table.
    for (let i = 0, len = Object.values(arr).length; i < len; i++) {
      // console.log(arr[i]);
      arrElem = arr[i];

      mappedArr[arrElem.strID] = arrElem;
      mappedArr[arrElem.strID]["children"] = [];
      mappedArr[arrElem.strID].leaf = false;
    }

    for (const id in mappedArr) {
      if (Object.prototype.hasOwnProperty.call(mappedArr, id)) {
        mappedElem = mappedArr[id];
        // If the element is not at the root level, add it to its parent array of children.
        // console.log(mappedArr[mappedElem[parent]]);
        if (mappedElem[parent] && mappedArr[mappedElem[parent]]) {
          mappedArr[mappedElem[parent]]["children"].push(mappedElem);
        }
        // If the element is at the root level, add it to first level elements array.
        else {
          tree.push(mappedElem);
        }
      }
    }
    // console.log(tree);
    return tree;
  },
  /**
   * Creates nested groups by object properties.
   * `properties` array nest from highest(index = 0) to lowest level.
   *
   * @param {String[]} properties
   * @returns {Object}
   */
  subGroupsBy: function (conversions: any[], property: any) {
    const group = conversions.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
    for (const key in group) {
      console.log(key);
      if (key != "0") {
        for (const keys in group[key]) {
          console.log(group[key][keys]);
        }
      }
    }
    return group;
  },

  nestGroupsBy: function (arr: any[], properties: any[]) {
    properties = Array.from(properties);
    if (properties.length === 1) {
      return this.groupBy(arr, properties[0]);
    }
    const property = properties.shift();
    const grouped = this.groupBy(arr, property);
    for (const key in grouped) {
      grouped[key] = this.nestGroupsBy(grouped[key], Array.from(properties));
    }
    return grouped;
  },

  /**
   * Group objects by property.
   * `nestGroupsBy` helper method.
   *
   * @param {String} property
   * @param {Object[]} conversions
   * @returns {Object}
   */
  groupBy: function (conversions: any[], property: any) {
    return conversions.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  },
};

export default Group;
