<template>
  <div class="row g-3">
    <div class="col-lg-4 col-sm-12">
      <Select
        :List="true"
        :size="sizeSelect"
        :strName="strSelectName"
        :DropdownItem="DropdownItem"
      />
    </div>
    <div class="col-lg-4 col-sm-6">
      <Textarea :label="strTextarea1" :require="true" />
    </div>
    <div class="col-lg-4 col-sm-6">
      <Textarea :label="strTextarea2" :require="true" />
    </div>
  </div>
</template>

<script>
import Textarea from "@/components/input/inputLongText.vue";
import Select from "@/components/input/dropdown/select.vue";
export default {
  components: {
    Select,
    Textarea,
  },
  props: {
    // DropdownItem: { type: Object },
    strSelectName: { type: String },
    strTextarea1: { type: String },
    strTextarea2: { type: String },
    sizeSelect: { type: String },
  },
  data() {
    return {
      DropdownItem:[
        {id:1,lang:"+66"},
        {id:2,lang:"+77"},
        {id:3,lang:"+88"},
        {id:4,lang:"+99"},
        {id:5,lang:"+11"},
        {id:6,lang:"+22"},
        {id:7,lang:"+33"},
      ]
    };
  },
};
</script>

<style></style>
