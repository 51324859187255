<template>
  <div
    :class="
      disable == true
        ? classDropdown + ' ' + 'select-dropdown-disable'
        : classDropdown + ' ' + 'select-dropdown'
    "
  >
    <!-- ส่วนของ Dropdown selector -->

    <div
      class="select rounded-2"
      @click="onClickDropdown()"
      :class="
        alertMsgStatus == true
          ? 'error'
          : '' || blnDrop == true
          ? 'active-dropdown'
          : '' || strResult != ''
          ? 'have-data' + ' ' + size
          : '' + size
        // blnDrop || strResult != '' || closed
        //   ? 'active-dropdown'
        //   : '' &&
        //     (error == true || blnDrop == true || strResult != '' || closed)
        //   ? 'error'
        //   : ''
      "
    >
      <!-- :class="alertMsgStatus == true ? 'invalid' : '' + size" -->
      <label
        class="text-black-500"
        for=""
        :class="strResult != '' || blnDrop ? 'move' : ''"
        >{{ $t(strName) + (request == true ? " *" : "") }}
      </label>

      <span id="result"
        >{{ strResult[this.fillterLang(strResult)] }}
        <!-- <span v-if="strResult.intCampusMain == 1" class="text-primary"> -->
        {{ strResult.intCampusMain == 1 ? " (" + $t("21") + ")" : "" }}
        <!-- </span> -->
      </span>

      <Icon
        icon="akar-icons:chevron-down"
        class="arrow-dropdown text-black-500"
        :class="blnDrop ? 'rotate-180' : ''"
      />
    </div>

    <!-- Dropdown list -->
    <transition name="dropdown">
      <div
        class="items bg-white w-100"
        v-if="blnDrop && canChange == true"
        :id="intId"
      >
        <div class="p-2" v-if="blnSearchBox == true">
          <Search
            :mode="'auto'"
            :hint="$t('447', { fieldname: $t(strName).toLowerCase() })"
            @onSearch="searchDropdown($event)"
            @onClean="clear()"
            ref="Search"
          />
        </div>
        <div
          class="item d-flex align-items-center text-black"
          v-for="(op, index) in listDropdown"
          :key="index"
          @click="onSeletction(op)"
        >
          {{ op[fillterLang(op)] }}
          <span v-if="op.intCampusMain == 1" class="ms-1 text-primary">
            {{ "(" + $t("21") + ")" }}
          </span>
        </div>
      </div>
    </transition>
  </div>
  <div
    :class="alertMsgStatus == true ? '' : 'd-none'"
    class="d-flex alertInput align-items-center p-0 mt-1 fnt-body fnt-regular"
  >
    <Icon
      :class="alertMsgStatus == true ? '' : 'd-none'"
      icon="carbon:warning"
      class="me-1"
    />
    {{ $t(alertMsg) }}
  </div>
  <div
    class="overlay"
    v-if="blnDrop"
    @click="
      () => {
        blnDrop = !blnDrop;
        searchDropdown(null);
      }
    "
  ></div>
</template>

<script>
import { Icon } from "@iconify/vue";
import customfilter from "@/typescript/filter";
import Search from "@/components/input/inputSearch.vue";

export default {
  data() {
    return {
      blnDrop: false,
      strResult: "",
      show: true,
      SelectedValue: [],
      listDropdown: [],
      locales: "en",
      error: true,
      alertMsgInput: "",
      alertMsgStatus: false,
    };
  },
  emits: ["selected"],
  props: {
    alertMsg: { type: String },
    request: { type: Boolean, default: false },
    canChange: { type: Boolean, default: true },
    strName: { type: String, default: "" },
    listDataSelection: { type: Array },
    intId: { type: Number },
    strValueName: { type: String, default: "" },
    intDataSelected: { tpye: Number },
    strSelectedValue: { type: Array },
    relations: { type: String, default: "" },
    classDropdown: { type: String },
    disable: { type: Boolean, default: false },
    blnReturnAll: { type: Boolean, default: false },
    blnSearchBox: { type: Boolean, default: false },
    blnDefaultSelected: { type: Boolean, default: false },
    size: { type: String, default: "" },
  },
  components: { Icon, Search },
  created() {
    // Default Selected dropdown
    setTimeout(() => {
      if (this.blnDefaultSelected == true) {
        if (this.listDataSelectioned != undefined) {
          this.onSeletction(this.listDataSelectioned[0]);
        }
      }
    }, 1200);
  },
  computed: {
    listDataSelectioned() {
      return this.listDataSelection;
    },
  },
  mounted() {
    setTimeout(() => {
      this.listDropdown = { ...this.listDataSelectioned };

      if (this.canChange == false) {
        let selected = Object.values({ ...this.listDataSelection }).filter(
          (ele) => {
            return ele.strID === this.intDataSelected;
          }
        );
        // console.log(selected);

        if (selected[0] !== undefined) {
          this.strResult = selected[0];

          this.blnDrop = false;
          this.$emit("selected", {
            state: this.strValueName,
            id: selected[0]["strID"],
          });
          this.$emit("input", {
            state: this.strValueName,
            id: selected[0]["id"],
          });
        }
      }
    }, 1000);
  },

  methods: {
    // on disable
    fetchData() {
      setTimeout(() => {
        this.listDropdown = { ...this.listDataSelectioned };
      }, 1000);
    },
    searchDropdown(key) {
      // console.log(key);
      if (key != null) {
        this.listDropdown = this.filterSelected(
          { ...this.listDataSelectioned },
          key
        );
      } else {
        this.listDropdown = { ...this.listDataSelectioned };
      }
      // console.log(this.listDropdown);
    },
    filterSelected(array, event) {
      // console.log(array);
      // const keysExact = ["NameEN", "NameTH"];
      return Object.values(array).filter((item) => {
        // if (this.$i18n.locale === "EN" || this.$i18n.locale === "en") {
        return (
          item[this.fillterLang(item)]
            .toString()
            .toLowerCase()
            .indexOf(event.toString().toLowerCase()) !== -1
        );
        // }
        // else {
        //   return (
        //     item[this.fillterLang(item)]
        //       .toString()
        //       .toLowerCase()
        //       .indexOf(event.toString().toLowerCase()) !== -1
        //   );
        // }
      });
    },
    onClickDropdown() {
      this.blnDrop = !this.blnDrop;
      this.alertMsgStatus = false;
    },
    alertRequest() {
      // console.log("Dropdown alertRequest");
      // this.alertMsg, { fieldname: this.$t(this.label).toLowerCase() };
      if (this.strResult == null || this.strResult == "") {
        // console.log("Dropdown null");
        this.alertMsgInput = this.alertMsg;
        // console.log("alertMsgInput :: " + this.alertMsgInput);

        this.alertMsgStatus = true;
        // console.log("alertMsgStatus :: " + this.alertMsgStatus);
      } else this.alertMsgStatus = false;
    },
    onClear() {
      this.strResult = "";
      this.blnDrop = false;
    },
    onClickOutside() {
      // if (this.blnDrop == true) {
      //   this.blnDrop = false;
      // }
      this.blnDrop = !this.blnDrop;
    },
    FilterDataSelection(relations) {
      let selected = "";
      if (relations == "") {
        return selected;
      }
      if (relations != "") {
        for (let { state, id } of this.strSelectedValue) {
          if (state == relations) {
            selected = id;
            return selected;
          }
        }
      }
    },
    fillterLang(items) {
      if (items !== undefined) {
        return customfilter.filterLang(items, this.$i18n.locale);
      }
    },
    async onSeletction(select) {
      // console.log(select);

      this.alertMsgStatus = false;

      this.strResult = await select;

      this.blnDrop = false;
      if (this.blnReturnAll == false) {
        this.$emit("selected", {
          state: this.strValueName,
          id: select["strID"],
        });
        this.$emit("input", {
          state: this.strValueName,
          id: select["id"],
        });
      } else if (this.blnReturnAll == true) {
        this.$emit("selected", select);
        this.$emit("input", select);
      }
    },
    oldSelection(id) {
      let selected = Object.values({ ...this.listDataSelection }).filter(
        (ele) => {
          return ele.strID === id;
        }
      );
      // console.log(selected);

      if (selected[0] !== undefined) {
        this.strResult = selected[0];

        this.blnDrop = false;
        this.$emit("selected", {
          state: this.strValueName,
          id: selected[0]["strID"],
        });
        this.$emit("input", {
          state: this.strValueName,
          id: selected[0]["id"],
        });
      }
    },
    filterListDataSelection() {
      var select = this.FilterDataSelection(this.relations);

      if (typeof select !== "undefined") {
        let filterList = [];

        filterList = Object.values(this.listDataSelection).filter((elem) => {
          return elem[this.relations] == select;
        });

        if (this.show == true) {
          // this.filterSelection(filterList);
        }
        if (filterList.length != 0) {
          return filterList;
        }
      } else if (typeof select === "undefined") {
        let filterList = Object.values(this.listDataSelection).filter(
          (elem) => {
            return elem[this.relations] == "" || elem[this.relations] == null;
          }
        );

        if (filterList.length != 0) {
          if (this.show == true) {
            this.filterSelection(filterList);
          }
          return filterList;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  // border-color: $danger !important ;
  border: solid 1px $danger !important;
}
.move {
  top: -16% !important;
  transform: translateY(-16%) !important;
  font-size: 14px;
  transition: 0.15s !important;
  left: 8px !important;

  &:hover {
    color: $primary;
  }
}
.active-dropdown {
  border: solid 1px $primary !important;
  label {
    color: $primary;
  }
}
.have-data {
  &:hover {
    label {
      color: $primary;
    }
  }
}
.rotate-180 {
  transform: rotate(180deg) translateY(50%) !important;
  top: 50%;
}
.dropdown-resize {
  height: 40px !important;
}
.select-dropdown {
  position: relative;
  width: 100%;
  cursor: pointer;

  .items {
    border-radius: 4px;
    border: solid 1px $black-400;
    height: fit-content;
    position: absolute;
    z-index: 10;
    top: 110%;
    .item {
      height: 46px;
      padding: 16px;
      &:hover {
        background-color: $black-300;
        cursor: pointer;
      }
    }
  }
  .select {
    background-color: $white;
    border: solid 1px $black-500;
    height: 46px;
    width: 100%;
    position: relative;
    label {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 8px;

      transform: translateY(-50%);
      padding: 2px 8px;
      transition: 0.15s;
      background-color: $white;
    }
    span {
      position: absolute;
      top: 50%;
      left: 16px;
      width: 80%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      transform: translateY(-50%);
    }
    .arrow-dropdown {
      padding: 0;
      margin: 0;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      transition: 0.15s;
    }

    &:hover {
      border: solid 1px $primary;
    }
  }
}
.select-dropdown-disable {
  // background-color: $black-300 ;
  pointer-events: none;
  position: relative;
  width: 100%;
  // cursor: pointer;
  .items {
    border-radius: 4px;
    border: solid 1px $black-400;
    height: fit-content;
    position: absolute;
    z-index: 10;
    top: 110%;
    .item {
      height: 46px;
      padding: 16px;
      &:hover {
        background-color: $black-400;
        cursor: pointer;
      }
    }
  }
  .select {
    background-color: $black-200;
    border: solid 1px $black-500;
    height: 46px;
    width: 100%;
    position: relative;
    label {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 8px;
      transform: translateY(-50%);
      padding: 2px 8px;
      transition: 0.15s;
      color: $black-400;
      background-color: $black-200;
      border-radius: 5px;
    }
    span {
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
    }
    .arrow-dropdown {
      padding: 0;
      margin: 0;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      transition: 0.15s;
    }
  }
}
.active-list {
  background-color: $black-300;
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: transparent;
  left: 0;
  top: 0;
  z-index: 2;
}
// Transition
.dropdown-enter-active,
.dropdown-leave-active {
  transition: opacity 150ms ease;
}
.dropdown-enter-from,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(-5px);
}
</style>