<template>
  <div
    class="alert-box"
    :blnalert="blnAltStatus || blnAltStatusunsucess"
    :class="blnAltStatus || blnAltStatusunsucess ? '' : 'd-none'"
  >
    <AltStatus
      :blnalert="blnAltStatus"
      icon="CorrectFill"
      coloricon="fill-success"
      class="success"
      :name="$t('28')"
    />
    <AltStatus
      :blnalert="blnAltStatusunsucess"
      icon="IncorrectFill"
      coloricon="fill-danger"
      class="error"
      :name="$t('174')"
    />
  </div>
  <Alert
    v-if="deleteListDataTable()"
    idName="ModalDlt"
    artIcon="DeleteFill"
    :altMsgBox="$t('186', { fieldname: $t(nametable).toLowerCase() })"
    @onInput="confirmDelete($event)"
    classIcon="fill-danger"
  />

  <Alert
    v-if="deleteListDataTable()"
    idName="ModalDlt"
    artIcon="DeleteFill"
    :altMsgBox="$t('186', { fieldname: $t(nametable).toLowerCase() })"
    @onInput="confirmDelete($event)"
    classIcon="fill-danger"
  />
  <Alert
    idName="ModalEdt"
    artIcon="ant-design:warning-outlined"
    altMsgBox="This Data is being used by the other system"
    classCancelBtn="d-none"
    classOkBtn="bg-warning text-white border-0 m-0"
    classIcon="text-warning"
  />

  <div class="d-flex align-items-center w-100 justify-content-center">
    <Loading v-if="progressScore <= 99" :percen="progressScore" />
  </div>
  <div class="popup-container" v-show="showDisplay == true">
    <transition name="zoom">
      <ConfigDisplay
        :DefaultField="ListDataTable.defaultField"
        :AllField="ListDataTable.dataFields"
        :SelectedListExcept="ListDataTable.selectedListExcept"
        :SelectedListOld="SelectedList"
        ref="config"
        v-if="showDisplay == true"
        @Ok="onSaveDisplay($event)"
        @Cancel="onShowConfigDisplay()"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="showDisplay == true"
        @click="showDisplay = false"
      ></div>
    </transition>
  </div>
  <div v-show="progressScore > 99">
    <div
      class="tree-container"
      @mouseleave="isDown = false"
      @mouseup="isDown = false"
      @mousedown="onTabledown"
      @mousemove="ontableScrollMove"
      :style="
        ListDataTable.total == 0 ||
        ListDataTable == null ||
        listSortData.length == 0
          ? 'height:fit-content;'
          : ''
      "
      :class="
        ListDataTable.total == 0 ||
        ListDataTable == null ||
        listSortData.length == 0
          ? 'none-scrollbar'
          : '' || device == 'mobile'
          ? 'tree-container-mobile'
          : ''
      "
    >
      <table class="table m-0" aria-describedby="tableTleAgt">
        <thead>
          <tr class style="text-align: left">
            <th scope="col" class="fnt-body" style="width: 0">
              <div class="d-flex align-items-center justify-content-center">
                <label
                  class="fnt-body fnt-bold text-black-500 ms-1"
                  style="margin-bottom: 1.55px !important"
                >
                  {{ $t("18") }}
                </label>
              </div>
            </th>
            <th scope="col" class="fnt-body" style="width: 0">
              <div class="d-flex align-items-center justify-content-center">
                <label
                  class="fnt-body fnt-bold text-black-500 ms-1"
                  style="margin-bottom: 1px !important"
                >
                  <!-- หัวของไอค่อน Expand -->
                </label>
              </div>
            </th>
            <th
              v-for="(items, index) in SelectedList"
              :key="index"
              scope="col"
              class="fnt-body"
            >
              <!-- <button class="btn fnt-body text-black-500 p-0 m-0" @click="listsortData(SelectedList[index].field, sort)"  > 
              {{ $t(items.head) }}
              <button/> -->
              <div class="d-flex align-items-center">
                <button
                  id="sortColumn"
                  @click="
                    listsortData(
                      SelectedList[index].field,
                      checkTableSort(SelectedList[index].field),
                      sort
                    )
                  "
                  class="btn p-0 m-0 fnt-body fnt-bold fnt-body text-black-500"
                  :class="
                    checkTableSort(SelectedList[index].field)
                      ? 'text-black'
                      : ''
                  "
                >
                  {{ $t(items.head) }}
                </button>
                <!-- <Button
                leftBtn="bi:arrow-down"
                classBtn="p-0 m-0 mb-1 sort text-black-500 ms-1 sorting-active"
                :class="checkTableSort(campus.state[index-1]) ? 'sorted text-black' : 'd-none'"
                width="14"
                height="14"
                class="arrow-sort"
                :class="
                  checkTableSort(SelectedList[index].field)
                    ? 'sorted text-black'
                    : 'd-none'
                "
                id="sortColumn"
                @click="listsortData(SelectedList[index].field, sort)"
              /> -->
                <button
                  id="sortColumn"
                  :class="
                    sort == true &&
                    checkTableSort(SelectedList[index].field, sort) == true
                      ? 'rotate-180 text-secondary'
                      : 'hover-none text-secondary '
                  "
                  @click="
                    listsortData(
                      SelectedList[index].field,
                      checkTableSort(SelectedList[index].field),
                      sort
                    )
                  "
                  class="fnt-body btn p-0 m-0 sorting-active"
                >
                  <SortArrow
                    :class="
                      checkTableSort(SelectedList[index].field, sort)
                        ? ''
                        : ' opacity-0'
                    "
                    width="18"
                    height="18"
                  />
                </button>
              </div>
            </th>
            <th
              :class="device == 'mobile' ? 'px-0' : ''"
              scope="col"
              style="height: 48px; width: 0"
            >
              <div class="d-flex justify-content-start">
                <Button
                  icon="DisplayConfig"
                  classBtn="stroke-secondary p-0 m-0"
                  @click="onShowConfigDisplay()"
                  width="20"
                  height="20"
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="border-0" v-if="listSortData.length != 0">
          <template v-for="(item, index) in arrayTreeObj" :key="index">
            <tr
              :class="item.highlight == true ? 'bg-secondary-50' : ''"
              class="border-bottom"
            >
              <th id="">
                <div
                  class="d-flex align-items-center justify-content-center fnt-regular fnt-body mt-1"
                  v-if="item.level == 0"
                >
                  <!-- {{ item.level }} -->
                  <!-- {{ index }}  -->

                  {{ item["indexnumber"] }}
                </div>
              </th>
              <th
                id=""
                @click="toggle(item, index)"
                class="px-0"
                style="cursor: pointer"
              >
                <div
                  class="d-flex align-items-center justify-content-center fnt-regular fnt-body mt-1"
                  v-if="item.level == 0"
                >
                  <!-- ใช้ class ชื่อว่า rotate-180 เพื่อทำให้เวลากดแล้วลูกศรจะลง -->
                  <moveDown
                    v-if="item.children.length != 0"
                    width="24"
                    height="24"
                    :class="
                      item.expend == 'undefined' || item.expend == none
                        ? 'rotate-0 fill-secondary'
                        : 'rotate-180 fill-secondary'
                    "
                    class="icon-hover"
                  />
                </div>
              </th>
              <td
                v-for="(itemsState, indexState) in SelectedList"
                :key="indexState"
                class="fnt-regular fnt-body first-row"
                @click="toggle(item, index)"
              >
                <div class="mt-1">
                  <!-- <td data-th="Name" @click="toggle(item, index)"> -->
                  <span
                    v-if="
                      itemsState.field !== 'intAgencyStatus' &&
                      itemsState.field !== 'candelete' &&
                      itemsState.field !== 'intAcorgstatus'
                    "
                    class="q-tree-link q-tree-label"
                    v-bind:style="
                      itemsState.field === fieldStrname ? setPadding(item) : ''
                    "
                  >
                    <!-- ใช้ class ชื่อว่า rotate-180 เพื่อทำให้เวลากดแล้วลูกศรจะลง -->
                    <!-- <moveDown
                      v-if="
                        item.children.length != 0 &&
                        itemsState.field == fieldStrname
                      "
                      width="24"
                      height="24"
                      :class="
                        item.expend == 'undefined' || item.expend == none
                          ? 'rotate-0 fill-secondary me-2'
                          : 'rotate-180 fill-secondary me-2'
                      "
                    /> -->
                    <span :class="device == 'mobile' ? '' : 'space'"></span>
                    <!-- item.children.length != 0 =>> มีลูก -->
                    {{ item[itemsState.field] }}
                  </span>
                  <span
                    v-if="
                      itemsState.field === 'intAgencyStatus' ||
                      itemsState.field === 'intAcorgstatus'
                    "
                    class="q-tree-link q-tree-label"
                    v-bind:style="
                      itemsState.field === fieldStrname ? setPadding(item) : ''
                    "
                  >
                    <Status
                      :name="
                        $t(
                          Translatestatus(
                            itemsState.field,
                            item[itemsState.field]
                          )
                        )
                      "
                      :labelclass="
                        TranslatestatusCss(
                          itemsState.field,
                          item[itemsState.field]
                        )
                      "
                    />
                    <!-- <Status
                    v-if="item[itemsState.field] == 1"
                    :name="$t('61')"
                    labelclass="bg-success"
                  /> -->
                  </span>
                  <!-- </td> -->
                </div>
              </td>
              <th :class="device == 'mobile' ? 'px-0' : ''" id="">
                <div
                  class="d-flex align-items-center"
                  v-if="device != 'mobile'"
                >
               
                  <Button
                  
                    icon="AddFill"
                    classBtnIcon="fill-secondary"
                    widthIcon="20"
                    heightIcon="20"
                    classBtn="p-0 m-0 tooltiper "
                    class="pe-3"
              
                    :blnTooltip="blnTooltip"
                    :tooltipMsg="tooltipMsg"
                    :tooltipDelay="tooltipDelay"
                    :tooltipPlacement="tooltipPlacement"
                    @click="addParent(item)"
                  />
                  <Button
                    icon="EditFill"
                    classBtnIcon="fill-secondary"
                    widthIcon="20"
                    heightIcon="20"
                    class="p-0 m-0 pe-3"
                    @click="editdata(item['strID'])"
                    classBtn="p-0 m-0"
                  />

                  <!-- <Button
                  v-if="
                    item['candelete'] == true && item['blnStatusDelete'] == true
                  "
                  icon="DeleteFill"
                  classBtnIcon="fill-secondary p-0 m-0"
                  widthIcon="20"
                  heightIcon="20"
                  dataToggle="modal"
                  @click="intID = item['strID']"
                  exampleModal="#ModalDlt"
                  classBtn="p-0 m-0"
                /> -->
                  <!-- ลบข้อมูลไม่ได้ ปุ่มเป็นสีเทา -->

                  <!-- <Button
                  v-if="
                    item['candelete'] == false ||
                    item['blnStatusDelete'] == false
                  "
                  icon="DeleteFill"
                  classBtnIcon="fill-black-500 tooltip-config "
                  widthIcon="20"
                  heightIcon="20"
                  @click="intID = item['strID']"
                  dataToggle="tooltip"
                  :tooltipMsg="$t('24')"
                  exampleModal="#ModalDlt"
                  classBtn="p-0 m-0"
                /> -->
                  <!-- ลบข้อมูลไม่ได้ ปุ่มเป็นสีเทา -->
                </div>
                <div
                  v-if="device == 'mobile'"
                  class="px-0 d-flex justify-content-end align-items-center bg-white"
                >
                  <Button
                    icon="DisplayConfig"
                    classBtn="stroke-secondary p-0 m-0"
                    @click="openOption(item)"
                    width="16"
                    height="16"
                  />
                </div>
              </th>
            </tr>
            <transition name="slide-up-mobile">
              <div
                class="d-flex align-items-start flex-column option"
                v-if="item.strID === idMobile && showOverlayMobile == true"
              >
                <div class="list-option w-100 px-4 py-4 bg-white">
                  <Button
                    icon="AddFill"
                    name="Add"
                    classBtnIcon="fill-secondary me-2"
                    widthIcon="20"
                    heightIcon="20"
                    classBtn="p-0 m-0 w-100 d-flex justify-content-start"
                    class="pe-3"
                    :blnTooltip="blnTooltip"
                    :tooltipMsg="tooltipMsg"
                    :tooltipDelay="tooltipDelay"
                    :tooltipPlacement="tooltipPlacement"
                    @click="addParent(item)"
                  />
                </div>
                <div class="list-option w-100 px-4 pt-4 pb-7 bg-white">
                  <Button
                    name="Edit"
                    icon="EditFill"
                    classBtnIcon="fill-secondary me-2"
                    widthIcon="20"
                    heightIcon="20"
                    class="p-0 m-0 pe-3 w-100 d-flex justify-content-start"
                    @click="editdata(item['strID'])"
                    classBtn="p-0 m-0"
                  />
                </div>
              </div>
            </transition>
          </template>
        </tbody>
      </table>
      <transition name="fade-tree">
        <div
          class="overlay-tree-mobile"
          v-show="showOverlayMobile == true"
          @click="showOverlayMobile = false"
        ></div>
      </transition>
      <NotFound
        v-if="
          ListDataTable.total == 0 ||
          ListDataTable == null ||
          listSortData.length == 0
        "
        :style="
          ListDataTable.total == 0 ||
          ListDataTable == null ||
          listSortData.length == 0
            ? 'height:fit-content;'
            : ''
        "
        class="border-bottom w-100 notfound"
        :name="nametable"
      />
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Skeleton.vue";
import { SortArrow } from "@/components/icon/index";
import ConfigDisplay from "../configDisplay/configDisplay.vue";
import { moveDown } from "@/components/icon/index";
import Status from "../label/statusLabel.vue";
import Button from "../button/button.vue";
import SortArray from "../../typescript/sort/sorting";
import NotFound from "../notFound/notFound.vue";
import Enumerable from "linq";
import AltStatus from "../alert/alertStatus.vue";
import Alert from "../alert/alert.vue";
export default {
  props: {
    ListDataTable: {},
    firstSort: { type: String },
    secondSort: { type: String },
    fieldStrname: { Type: String },
    nametable: { type: String },
    progressScore: { type: Number },
    blnTooltip: { type: Boolean, default: false },
    tooltipMsg: { type: String, default: "" },
    tooltipDelay: { type: String, default: "150ms" },
    tooltipPlacement: { type: String, default: "tooltip-top" },
  },
  components: {
    ConfigDisplay,
    //Icon,
    Status,
    Button,
    moveDown,
    NotFound,
    SortArrow,
    Loading,
    Alert,
    AltStatus,
  },
  emits: [
    "edit",
    "delete",
    "ConfigDisplay",
    "onSaveConfigDisplay",
    "addParent",
  ],
  watch: {
    selectedIDRow: function (val) {
      if (this.selectedRowID != null) {
        this.$set(this.selectedRowID, "strID", val);
      }
    },
  },
  computed: {
    arrayTreeObj() {
      let vm = this;
      var newObj = [];

      vm.recursive(this.listSortData, newObj, 0, this.itemId, vm.isExpanded);

      return newObj;
    },
    arrayTree() {
      let vm = this;
      var newObj = [];
      vm.recursive(vm.table, newObj, 0, this.itemId, vm.isExpanded);

      return newObj;
    },
  },
  data() {
    return {
      showOverlayMobile: false,
      idMobile: 0,
      sort: false,
      showCampus: true,
      fieldSort: null,
      srtNameEn: true,
      srtInitialEn: true,
      srtNameTH: true,
      srtInitialTH: true,
      blnAltStatus: false,
      blnAltStatusunsucess: false,
      SelectedList: [],
      listSortData: [],
      currentData: [],
      showDisplay: false,
      isExpanded: true,
      selectedRowID: {},
      indexnumber: 0,
      loopNumber: true,
      itemId: null,
    };
  },

  methods: {
    openOption(event) {
      this.idMobile = event.strID;
      this.showOverlayMobile = true;
      // console.log(event.strID, this.showOverlayMobile);
    },
    Translatestatus(field, status) {
      if (field === "intAgencyStatus") {
        let data = [
          { id: 1, lang: "61" }, //open
          { id: 0, lang: "62" }, //close
        ];
        let s = data.filter((elem) => elem.id == status);
        return s[0].lang;
      } else if (field === "intAcorgstatus") {
        let data = [
          { id: 3, lang: "61" }, //open
          { id: 2, lang: "170" }, //In process
          { id: 1, lang: "171" }, //Suspend
          { id: 0, lang: "62" }, //close
        ];
        let s = data.filter((elem) => elem.id == status);
        return s[0].lang;
      }
    },
    TranslatestatusCss(field, status) {
      if (field === "intAgencyStatus") {
        let data = [
          { id: 1, css: "bg-success" }, //open
          { id: 0, css: "bg-black-500" }, //close
        ];
        let s = data.filter((elem) => elem.id == status);
        return s[0].css;
      } else if (field === "intAcorgstatus") {
        let data = [
          { id: 3, css: "bg-success" }, //open
          { id: 2, css: "bg-warning" }, //In process
          { id: 1, css: "bg-danger" }, //Suspend
          { id: 0, css: "bg-black-500" }, //close
        ];
        let s = data.filter((elem) => elem.id == status);
        return s[0].css;
      }
    },
    addParent(id) {
      // console.log(id);
      this.showOverlayMobile = false;
      this.$emit("addParent", id);
    },
    editdata(id) {
      this.showOverlayMobile = false;
      this.$emit("edit", id);
    },
    onSaveDisplay(data) {
      this.$emit("onSaveConfigDisplay", data);
      this.showDisplay = !this.showDisplay;

      if (this.showDisplay == false) {
        this.$emit("ConfigDisplay");
      }
    },

    onShowConfigDisplay() {
      this.showDisplay = !this.showDisplay;

      if (this.showDisplay == false) {
        this.$emit("ConfigDisplay");
      }
    },
    //ตรวจสอบสถานะการเรียงข้อมูล
    checkTableSort(field) {
      // ถ้า fillter แล้วไม่มีข้อมูล จะไม่แสดงลูกศร (sort ไม่ได้)
      if (this.listSortData.length == 0) {
        return false;
      }

      if (field == this.fieldSort) {
        return true;
      } else if (field != this.fieldSort) {
        return false;
      }
    },
    notify: function () {
      this.$q.notify("Running on Quasar v" + this.$q.version);
    },
    setSelectedList(data) {
      if (data.isTrusted != true) {
        this.SelectedList = data;
      } else if (data.isTrusted == true) {
        this.SelectedList = { ...this.ListDataTable.defaultField };
      }

      this.listSortData = { ...this.ListDataTable.data };
      this.currentData = this.ListDataTable.data;
      // this.currentData = this.ListDataTable.data.slice();

      setTimeout(() => {
        const s = this.SelectedList.filter((item) => {
          return item.field == this.firstSort;
        });

        if (s.length > 0) {
          this.fieldSort = this.firstSort;

          this.listSortData = this.resetData(this.ListDataTable.data);

          this.listSortData = this.SortArrayMultiplyArrayObjTree(
            this.listSortData,
            this.firstSort,
            this.secondSort
          );
          // console.log(this.listSortData);

          let indexnumber = 1;
          for (let obj in this.listSortData) {
            this.listSortData[obj].indexnumber = indexnumber;
            indexnumber++;
          }

          this.sort = false;
        } else if (s.length == 0) {
          this.fieldSort = this.secondSort;
          this.listSortData = this.SortArrayObjTree(
            this.ListDataTable.data,
            this.fieldSort
          );

          let indexnumber = 1;
          for (let obj in this.listSortData) {
            this.listSortData[obj].indexnumber = indexnumber;
            indexnumber++;
          }

          this.sort = false;
        }
      }, 10);
    },
    setIndexnumber() {
      let indexnumber = 1;
      for (let obj in this.listSortData) {
        this.listSortData[obj].indexnumber = indexnumber;
        indexnumber++;
      }
    },

    rootx(item) {
      if (item == "0") {
        this.indexnumber++;
        //console.log(this.indexnumber);
      }
    },
    recursive(obj, newObj, level, itemId, isExpend) {
      let vm = this;
      if (Array.isArray(obj)) {
        obj.forEach(function (o) {
          if (o.children && o.children.length != 0) {
            o.level = level;

            o.leaf = false;
            newObj.push(o);
            if (o.strID === itemId) {
              o.expend = isExpend;
            }
            if (o.expend === true) {
              vm.recursive(o.children, newObj, o.level + 1, itemId, isExpend);
            }
          } else {
            o.level = level;
            o.leaf = true;
            newObj.push(o);
            return false;
          }
        });
      }
    },

    toggle(item) {
      this.indexnumber = 0;
      let vm = this;
      vm.itemId = item.strID;

      item.leaf = false;

      //show  sub items after click on + (more)
      if (
        item.leaf == false &&
        item.expend === "undefined" &&
        item.children !== "undefined"
      ) {
        if (item.children.length != 0) {
          vm.recursive(item.children, [], item.level + 1, item.strID, true);
        }
      }

      if (item.expend == true && item.children != "undefined") {
        //var __subindex = 0;

        item.children.forEach(function (o) {
          o.expend = "undefined";
        });
        item.expend = "undefined";
        item.leaf = false;

        vm.itemId = null;
      }
    },
    listsortData(field, it, s) {
      // ถ้า fillter แล้วไม่มีข้อมูล จะ sort ไม่ได้
      if (this.listSortData.length == 0) {
        return;
      }

      this.fieldSort = field;

      if (field == null) {
        return false;
      }
      if (it === false) {
        s = true;
      }
      // console.log(this.fieldSort !== this.firstSort);

      //sort A->Z
      if (s == true) {
        if (this.fieldSort !== this.firstSort) {
          this.listSortData = this.SortArrayReversObjTree(
            this.currentData.slice(),
            field
          );
          let indexnumber = 1;
          for (let obj in this.listSortData) {
            this.listSortData[obj].indexnumber = indexnumber;
            indexnumber++;
          }

          //this.listSortData = this.currentData;
          //this.currentData = this.ListDataTable.data.slice();
          this.sort = false;
        } else {
          this.listSortData = this.SortArrayObjTree(
            this.currentData.slice(),
            field
          );

          let indexnumber = 1;
          for (let obj in this.listSortData) {
            this.listSortData[obj].indexnumber = indexnumber;
            indexnumber++;
          }
          //this.listSortData = this.currentData;
          //this.currentData = this.ListDataTable.data.slice();
          this.sort = false;
        }
      }
      //sort Z->A
      if (s == false) {
        if (this.fieldSort !== this.firstSort) {
          this.listSortData = this.SortArrayObjTree(
            this.currentData.slice(),
            field
          );

          let indexnumber = 1;
          for (let obj in this.listSortData) {
            this.listSortData[obj].indexnumber = indexnumber;
            indexnumber++;
          }
          //this.listSortData = this.currentData;
          //this.currentData = this.ListDataTable.data.slice();
          this.sort = true;
        } else if (this.fieldSort === this.firstSort) {
          this.listSortData = this.SortArrayReversObjTree(
            this.currentData.slice(),
            field
          );
          let indexnumber = 1;
          for (let obj in this.listSortData) {
            this.listSortData[obj].indexnumber = indexnumber;
            indexnumber++;
          }

          //this.listSortData = this.currentData;
          //this.currentData = this.ListDataTable.data.slice();
          this.sort = true;
        }
      }
    },
    SortArrayMultiplyArrayObjTree(sortdata, firstSort, secondSort) {
      let vm = this;

      const data = Enumerable.from(sortdata)
        .orderByDescending((r) => r[firstSort].toString().toLowerCase())
        .thenBy((r) => r[secondSort].toString().toLowerCase())
        .toArray();

      if (Array.isArray(data)) {
        data.forEach(function (o) {
          if (o.children?.length != 0) {
            vm.SortArrayMultiplyArrayObjTree(o.children, firstSort, secondSort);
          }
        });
      }

      return data;
    },
    SortArrayReversObjTree(sortdata, field) {
      let vm = this;
      sortdata = SortArray.SingleArrayReversSort(sortdata, field);
      sortdata.forEach(function (o) {
        if (o.children.length != 0) {
          vm.SortArrayReversObjTree(o.children, field);
          //o.children = SortArray.SingleArrayReversSort(o.children, field);
        }
      });
      return sortdata;
    },
    SortArrayObjTree(sortdata, field) {
      // console.log(field);
      let vm = this;
      sortdata = SortArray.SingleArraySort(sortdata, field);
      sortdata.forEach(function (o) {
        if (o.children.length != 0) {
          vm.SortArrayObjTree(o.children, field);
          //o.children = SortArray.SingleArraySort(o.children, field);
        }
      });

      return sortdata;
    },
    setPadding(item) {
      return `padding-left: ${item.level * 16}px;`;
    },
    SearchListDataTable(event) {
      if (event !== null && event.toString() !== "") {
        this.listSortData = this.filter(this.currentData.slice(), event);
        let indexnumber = 1;
        for (let obj in this.listSortData) {
          this.listSortData[obj].indexnumber = indexnumber;
          indexnumber++;
        }
      } else if (event == null || event.toString() == "") {
        this.listSortData = this.resetData(this.ListDataTable.data);

        this.listSortData = this.SortArrayMultiplyArrayObjTree(
          this.listSortData,
          this.firstSort,
          this.secondSort
        );
      }
    },
    filter(array, text) {
      const getNodes = (result, object) => {
        if (Array.isArray(object.children)) {
          const children = object.children.reduce(getNodes, []);

          if (children.length) {
            object.expend = true;
            result.push({ ...object, children });
            return result;
          }
        }
        if (this.filterSelected(object, this.SelectedList, text)) {
          object.highlight = true;
          object.expend = true;
          result.push(object);
          return result;
        }

        return result;
      };

      return array.slice().reduce(getNodes, []);
    },
    filterSelected(item, filters, event) {
      // console.log(array);
      const filterKeys = Object.keys(filters);

      // validates all filter criteria
      return filterKeys.some((key) => {
        // ignores non-function predicates

        return (
          item[filters[key].field]
            .toString()
            .toLowerCase()
            .indexOf(event.toString().toLowerCase()) !== -1
        );
      });
    },
    //ยกเลิกการค้นข้อมูล
    //ยกเลิกการค้นข้อมูล

    onClean() {
      this.setSelectedList(this.SelectedList);

      this.listSortData = this.ListDataTable.data;
      this.currentData = this.ListDataTable.data;
      // this.listsortData(this.field, this.checkTableSort(this.field), this.sort);
    },

    resetData(array) {
      let vm = this;
      if (Array.isArray(array)) {
        array.forEach(function (o) {
          if (o.children && o.children.length != 0) {
            o.expend = "undefined";
            o.highlight = false;

            vm.resetData(o.children);
          } else {
            o.expend = "undefined";
            o.highlight = false;
          }
        });

        return array;
      }
    },
    deleteListDataTable() {
      return true;
    },

    confirmDelete(state) {
      if (state == true) {
        this.$emit("delete", this.intID);
        // console.log(this.intID);
      } else if (state == false) {
        return false;
      }
    },
    checkstateofdelete(state) {
      if (state == true) {
        setTimeout(() => {
          this.checkstateofalert();
        }, 2000);
        this.blnAltStatus = true;
        setTimeout(() => {
          this.blnAltStatus = false;
        }, 2000);
        return true;
      } else if (state == false) {
        setTimeout(() => {
          this.checkstateofalert();
        }, 2000);
        this.blnAltStatusunsucess = true;
        setTimeout(() => {
          this.blnAltStatusunsucess = false;
        }, 2000);
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.zoom-enter-from,
.zoom-leave-to {
  opacity: 0;
}
.zoom-enter-active {
  animation: bounce-in 150ms;
}
.zoom-leave-active {
  opacity: 0;
}
@keyframes bounce-in {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
.popup-container {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  .overlay {
    background-color: rgba(0, 0, 0, 0.263);
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
// Mobile action
.fade-tree-enter-active,
.fade-tree-leave-active {
  transition: opacity 0.5s ease;
}

.fade-tree-enter-from,
.fade-tree-leave-to {
  opacity: 0;
}
.slide-up-mobile-enter-active,
.slide-up-mobile-leave-active {
  transition: transform 150ms;
}

.slide-up-mobile-enter-from,
.slide-up-mobile-leave-to {
  transform: translateY(100%);
}
.overlay-tree-mobile {
  // opacity: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.341) !important;
  z-index: 9998;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.option {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $white;
  z-index: 9999;
  transition: transform 150ms;
  .list-option {
    background-color: $white;
  }
}
.tree-container-mobile {
  &::-webkit-scrollbar {
    display: none;
  }
}
.tree-container {
  width: auto;
  overflow-x: auto;
  overflow-y: auto;
  max-height: calc(100vh - 303px);
  .table {
    overflow-y: auto;
    thead {
      position: sticky;
      top: 0;
      background-color: $white;
      z-index: 2;
      &::after {
        content: "";
        height: 1px;
        width: 100%;
        background-color: $black-300;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
      }
      tr {
        th {
          height: 48px;
        }
        th:first-child {
          position: sticky;
          left: 0;
          background-color: $white;
          z-index: 2;
          // z-index: 15;
        }
        th:last-child {
          position: sticky;
          right: 0;
          background-color: $white;
          z-index: 2;
        }
      }
    }
    tbody {
      tr {
        th:first-child {
          position: sticky;
          left: 0;
          background-color: $white;
          z-index: 1;
        }
        th:last-child {
          position: sticky;
          right: 0;
          background-color: $white;
          z-index: 0;
        }
        &:hover {
          th:first-child {
            background-color: $black-50 !important;
          }
          th:last-child {
            background-color: $black-50 !important;
          }
        }
      }
    }
    td {
      padding-right: 1rem;
      height: 44px;
      width: fit-content;
      // padding: 15px auto;
      div {
        white-space: nowrap;
      }
    }
    th {
      padding-right: 1rem;
      white-space: nowrap;
    }
    tr {
      padding-right: 1rem;
      white-space: nowrap;
    }
  }
}
.rotate-180 {
  transition: transform 0.2s;
  transform: rotate(180deg);
}
.rotate-0 {
  transition: transform 0.2s;
  transform: rotate(0deg);
}
.fill-secondary {
  fill: $secondary !important;
}
.sorting-active {
  border-radius: 50%;
  padding: 8px;
  width: 24px;
  height: 24px;
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover {
    background-color: $black-300 !important;
  }
}
.first-row:nth-child(2) {
  .space {
    padding-left: 32px !important;
  }
}
.sorting-active {
  border-radius: 50%;
  padding: 8px;
  width: 24px;
  height: 24px;
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover {
    background-color: $black-300 !important;
  }
}
.hover-none {
  &:hover {
    background: none !important;
  }
}
.alert-box {
  background-color: rgba(255, 255, 255, 0.5);
  width: calc(100% - 220px);
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
}
.space-mobile:nth-child(2) {
  padding-left: 16px !important;
}
</style>
