<template>
  <div class="d-flex justify-content-between align-items-center mb-4">
    <Label :label="$t(label)" class="fnt-medium fnt-subtitle" />
    <Label
      :label="$t('422')"
      icon="QuestionOutline"
      :helper="true"
      classBtn="tooltiper"
      tooltipPlacement="tooltip-right"
      :tooltipMsg="$t('423')"
      class="fnt-regular fnt-body"
      classBtnIcon="fill-black"
    />
  </div>

  <div class="row gx-3 p-0 m-0">
    <div class="col-lg-4 col-sm-12 mx-0 px-0 pe-lg-2 mb-3">
      <InputNumber
        :maxvalue="maxvalueyear"
        @input="updateData('year', $event)"
        :label="year"
        :value="listWorkPeriod['year']"
        :showTxtInp="showTxtInp['year']"
        ref="year"
        :alertMsg="alertMsg"
        validate="WorkPeriod"
        :maxcharacters="2"
      />
    </div>
    <div class="col-lg-4 col-sm-12 mx-0 px-0 px-lg-2 mb-3">
      <InputNumber
        :maxvalue="maxvaluemonth"
        @input="updateData('month', $event)"
        :value="listWorkPeriod['month']"
        :label="month"
        :showTxtInp="showTxtInp['month']"
        ref="month"
        :alertMsg="alertMsg"
        validate="WorkPeriod"
        :maxcharacters="2"
      />
    </div>
    <div class="col-lg-4 col-sm-12 mx-0 px-0 ps-lg-2 mb-3">
      <InputNumber
        @input="updateData('day', $event)"
        :value="listWorkPeriod['day']"
        :maxvalue="maxvalueday"
        :label="day"
        ref="day"
        :showTxtInp="showTxtInp['day']"
        :alertMsg="alertMsg"
        validate="WorkPeriod"
        :maxcharacters="2"
      />
    </div>
    <div
      :class="alertMsgStatus == true ? '' : 'd-none'"
      class="d-flex alertInput align-items-center p-0 mt-1 fnt-body fnt-regular"
    >
      <Icon
        :class="alertMsgStatus == true ? '' : 'd-none'"
        icon="carbon:warning"
        class="me-1"
      />
      {{ $t(alertMsgInput) }}
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";

import Label from "@/components/label/label.vue";
import InputNumber from "@/components/input/inputNumber.vue";
export default {
  data() {
    return {
      data: "",
      listWorkPeriod: { year: "0", month: "0", day: "0" },
      showTxtInp: {},
      max: 60,
      length: 2,

      alertMsgStatus: false,
      alertMsgInput: "",
    };
  },
  components: {
    Icon,
    InputNumber,
    Label,
  },
  props: {
    label: { type: String, default: "" },
    year: { type: String, default: "" },
    month: { type: String, default: "" },
    day: { type: String, default: "" },
    maxvalueyear: { type: String, default: "" },
    maxvaluemonth: { type: String, default: "" },
    maxvalueday: { type: String, default: "" },
    alertMsg: { type: String },
    value: { type: String, default: "" },
  },
  emits: ["input"],
  mounted() {
    setTimeout(() => {
      // console.log(this.value);
      // console.log(this.value.toString() == "");
      if (this.value.toString() !== "" && this.value != undefined) {
        // this.listWorkPeriod = this.value;
        this.listWorkPeriod = {
          year: parseInt(this.value.year).toString(),
          month: parseInt(this.value.month).toString(),
          day: parseInt(this.value.day).toString(),
        };
      } else {
        this.listWorkPeriod = { year: "0", month: "0", day: "0" };
      }
    }, 500);
  },
  methods: {
    alertRequest() {
      this.$refs["year"].alertRequest();
      this.$refs["month"].alertRequest();
      this.$refs["day"].alertRequest();
      // if((this.$refs["year"].value == "0"||this.$refs["year"].value == "00") && (this.$refs["month"].value == "0"||this.$refs["month"].value == "00") && (this.$refs["day"].value == "0" || this.$refs["day"].value == "00")){

      if (
        this.$refs["year"].value +
          this.$refs["month"].value +
          this.$refs["day"].value <
        1
      ) {
        this.alertMsgInput = "424";
        this.alertMsgStatus = true;
      } else this.alertMsgStatus = false;
    },
    Valid() {
      this.alertMsgStatus = false;
      this.alertRequest();
    },
    updateData(state, event) {
      // console.log(event);
      // console.log(state);
      setTimeout(() => {
        // console.log(this.listWorkPeriod[state]);
        // console.log(typeof event == "number");
        if (typeof event == "number") {
          this.listWorkPeriod[state] = event.toString();
          // console.log(this.listWorkPeriod[state]);
          if (
            Number(this.listWorkPeriod["year"]) >= Number(this.maxvalueyear)
          ) {
            {
              this.alertMsgInput = "425";
              this.alertMsgStatus = true;
            }
            //   if (
            //   Number(this.listWorkPeriod["year"]) > Number(this.maxvalueyear)
            // ) {this.alertMsgInput = "425";
            //   this.alertMsgStatus = true;}

            this.listWorkPeriod["year"] = this.maxvalueyear;
            this.listWorkPeriod["month"] = "0";
            this.listWorkPeriod["day"] = "0";
            this.showTxtInp["month"] = false;
            this.showTxtInp["day"] = false;
            this.$emit("input", this.listWorkPeriod);
            // console.log(this.$refs["month"].listWorkPeriod);
          } else {
            this.alertMsgStatus = false;
            this.$emit("input", this.listWorkPeriod);
            this.showTxtInp["month"] = true;
            this.showTxtInp["day"] = true;
          }
        } else if (typeof event !== "number") {
          // console.log(event.target.value);
          // console.log(this.listWorkPeriod);
          this.listWorkPeriod[state] = event.target.value;

          if (
            Number(this.listWorkPeriod["year"]) >= Number(this.maxvalueyear)
          ) {
            {
              this.alertMsgInput = "425";
              this.alertMsgStatus = true;
            }
            //   if (
            //   Number(this.listWorkPeriod["year"]) > Number(this.maxvalueyear)
            // ) {this.alertMsgInput = "425"; this.alertMsgStatus = true;}

            this.listWorkPeriod["year"] = this.maxvalueyear;
            this.listWorkPeriod["month"] = "0";
            this.listWorkPeriod["day"] = "0";
            this.$emit("input", this.listWorkPeriod);
            this.showTxtInp["month"] = false;
            this.showTxtInp["day"] = false;
            // console.log(this.$refs["month"].listWorkPeriod);
          } else {
            this.alertMsgStatus = false;

            this.$emit("input", this.listWorkPeriod);
            this.showTxtInp["month"] = true;
            this.showTxtInp["day"] = true;
          }
        }
      }, 30);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>