<template>
  <div
    class="popup-container align-items-center"
    v-if="intWrongLogin >= intPinCount"
  >
    <transition name="zoom">
      <AlertPopup
        v-if="intWrongLogin >= intPinCount"
        type="Warning"
        classOkBtn="btn-secondary"
        artIcon="WarningOutline"
        :altMsgBox="$t('1287', { LONGINFAILNUM: intPinCount, LockContact: strTel })"
        :Helper="$t('345')"
        @confirm="reloadpage()"
        ref="AlertPopupp"
        :btnpop="true"
        classIcon="fill-warning"
        :Cancel="false"
      />
    </transition>
    <transition name="fade">
      <div class="overlay" v-if="intWrongLogin >= intPinCount"></div>
    </transition>
  </div>
  <!-- หน่วยงานซ้ำ -->
  <div
    class="popup-container align-items-center"
    v-show="blnAltDupicateAcademic"
  >
    <transition name="zoom">
      <AlertPopup
        v-show="blnAltDupicateAcademic"
        :altMsgBox="$t('204')"
        classOkBtn="btn-secondary text-white"
        classCancelBtn="text-warning"
        artIcon="WarningOutline"
        @confirm="confirmAltPopup($event)"
        type="warning"
        classIcon="fill-warning"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnAltDupicateAcademic"
        @click="ConfirmDelete()"
      ></div>
    </transition>
  </div>

  <div
    class="popup-container align-items-center"
    v-show="blnPopupConfirmDelete && intWrongLogin < intPinCount"
  >
    <transition name="zoom">
      <AlertPopup
        v-show="blnPopupConfirmDelete"
        type="Danger"
        classOkBtn="btn-danger"
        artIcon="deleteFill"
        :altMsgBox="$t('186', { fieldname: $t('3').toLowerCase() })"
        :Helper="$t('345')"
        @confirm="fetchDeleteAgency($event)"
        :blnLoading="blnloadConfirmDelet"
        ref="AlertPopup"
        :showpass="true"
        :btnpop="true"
        classIcon="fill-danger"
        :errorMsg="$t(errorMsg)"
        :blnError="blnError"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnPopupConfirmDelete"
        @click="blnloadConfirmDelet == false ? fetchDeleteAgency(false) : ''"
      ></div>
    </transition>
  </div>
  <!-- </div> -->
  <!-- <div
    class="alert-box"
    :blnalert="blnAltStatus || blnAltStatusunsucess || blnAltLoadingData"
    :class="
      blnAltStatus || blnAltStatusunsucess || blnAltLoadingData ? '' : 'd-none'
    "
  > -->
  <AltStatus
    :blnalert="blnAltStatus"
    icon="CorrectFill"
    coloricon="fill-success"
    class="success"
    :name="$t(strSMSGsuccess)"
    :class="blnAltStatus ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnAltStatusunsucess"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('174')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltStatusunsucess ? 'show' : ''"
  />

  <AltStatus
    :blnalert="blnAltLoadingData"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('174')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltLoadingData ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnAltLoading"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('172')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltLoading ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnnotdelete"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('428')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnnotdelete ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnnotedit"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('222')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnnotedit ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnnotaddchild"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('429')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnnotaddchild ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnstatusOpent"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('430')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnstatusOpent ? 'show' : ''"
  />
  <!-- </div> -->
  <Header
    icon="agencyOutline"
    :headerName="$t('3')"
    :breadcrumbname="
      action == 'Insert' ? $t('156') : '' || action == 'Update' ? $t('393') : ''
    "
    :btnName="$t('156')"
    class="mb-4"
    @onInput="
      {
        action = 'Insert';
        openform($event, intCampusId, 0);
      }
    "
    :breadcrumb="blnOpenform == true ? '' : 'd-none'"
    :helperHeader="
      blnOpenform == true ? $t('200', { fieldname: $t('3').toLowerCase() }) : ''
    "
    :hintHelperHeader="blnOpenform == true ? '' : 'd-none'"
    :rightHeader="blnOpenform == true ? 'd-none' : ''"
    :underline="blnOpenform == true ? 'openUnderline' : 'closeUnderline'"
    @showFilter="openFilter($event)"
    id="header"
    :Searchname="$t('155')"
    @onSearch="SearcAgency($event)"
    @onClean="clean()"
    :blnOpenform="blnOpenform"
    :showFilter="blnOpenFilter"
    :filterActive="{
      blnOpenform: blnOpenFilter,
      useFilter: activeFiltersSelected.length == 0 ? false : true,
      disableFilter: false,
    }"
    :dataselection="Listcampus.data"
    @selectOption="selectCampus($event)"
    ref="header"
  />

  <!-- <div class="bg-white admin-form-campus rounded-2 shadow-sm ms-9 mb-5">
    <StatusCode
      codeNumber="500"
      detail="There was an error. Please try again later."
      message="Internal server error"
    />
  </div> -->

  <div
    v-show="blnOpenform == false"
    class="px-4 pt-4 pb-5 bg-white rounded-2 shadow-sm"
  >
    <!-- <Select
      strName="Campus"
      class="d-none"
    /> -->
    <div
      class="select-campus mb-2"
      :class="ListCampusClass || device == 'mobile' ? 'w-100' : ''"
    >
      <Dropdown
        :blnReturnAll="false"
        :strName="$t('2')"
        @selected="selectCampus($event)"
        :listDataSelection="Listcampus.data"
        :blnSearchBox="true"
        :blnDefaultSelected="true"
        :alertMsg="$t('284', { fieldname: $t('3').toLowerCase() })"
        size="dropdown-resize"
        ref="Dropdown"
      />
      <!-- <select
        aria-label="Default select example"
        class="border form-select text-primary dropdown-header pe-3 mb-2"
        @change="selectCampus($event.target.value)"
      >
        <option
          v-for="(op, index) in Listcampus.data"
          :key="index"
          style="height: 48px !important"
          class="text-primary"
          :value="op['strID']"
          :selected="op['strID'] == intCampusId ? true : false"
        >
          {{
            changeCampusList
              ? $t(op["strCampusNameEN"])
              : $t(op["strCampusNameTH"])
          }}
        </option>
      </select> -->
    </div>
    <div class="w-100" v-show="!blnOpenform">
      
      <AgengyTable
        :progressScore="progress"
        fieldStrname="strAgencyNameEN"
        :ListDataTable="ListAgency"
        secondSort="strAgencyNameEN"
        firstSort="intAgencyStatus"
        ref="table"
        :blnTooltip="true"
        :tooltipMsg="$t('1271')"
        tooltipPlacement="tooltip-left-edit"
        @ConfigDisplay="reloadConfigDisplay()"
        @onSaveConfigDisplay="onSaveConfigDisplay($event)"
        @addParent="
          // {
          //   action = 'Insert';
          //   openform(true, intCampusId, $event);
          // }
          addParent(intCampusId, $event)
        "
        @edit="fetchGetAgencybyId($event)"
        :nametable="'3'"
      />
    </div>
  </div>
  <div class="popup-container" v-show="blnOpenFilter == true">
    <transition name="zoom">
      <Filter
        v-show="blnOpenFilter == true"
        @appectFilter="filteredData($event)"
        :listDataFilter="listAgencyFilter"
        @Cancel="blnOpenFilter = !blnOpenFilter"
        :listActiveFiltersSelected="activeFiltersSelected"
        :blnOpenFilter="blnOpenFilter"
        :blnHaveDecision="false"
        ref="filter"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnOpenFilter == true"
        @click="blnOpenFilter = false"
      ></div>
    </transition>
  </div>
  <div
    class="d-flex mt-sm-3 ms-lg-9 m-sm-0 justify-content-start align-items-start"
    v-if="blnOpenform == true"
    :class="
      device == 'mobile' || device == 'tablet'
        ? 'flex-wrap move-to-top-level'
        : ''
    "
  >
    <div
      class="form-lg rounded-2 shadow-sm bg-white me-lg-5 me-sm-0 layout"
      :class="device == 'mobile' ? 'mb-5 me-0 w-100' : 'form-lg-fix mb-4'"
    >
      <Form
        v-if="blnOpenform == true"
        :typeSchema="typeSchema"
        :data="formdata"
        @onInput="openform($event)"
        @Insert="fetchCheckDupicateAgency($event)"
        @Update="fetchCheckDupicateAgency($event)"
        @delete="ConfirmDelete()"
        :action="action"
        :request="request"
        :inactive="formInactive"
        ref="form"
      />
    </div>

    <Relate
      :class="device == 'mobile' || device == 'tablet' ? 'mt-0 mb-3 w-100' : ''"
      v-if="blnOpenform == true && listRelate.used == true"
      :listRelate="listRelate"
      :label="$t('418', { fieldname: $t('417').toLowerCase() })"
      class="layout"
    />
  </div>
</template>
<script>
import Relate from "@/components/cards/relate.vue";
import AgengyTable from "@/components/table/tableAgt.vue";
import Header from "@/components/headerMenu/haeder.vue";
import Form from "@/components/form/form.vue";
import { typeSchema, request } from "@/components/constants/schemaAddAgency";
import Filter from "@/components/filter/filter.vue";
import APIAgency from "@/typescript/urlapi/adminsystem/APIAgency";
import APICampus from "@/typescript/urlapi/adminsystem/APICampus";
import axios from "axios";
import urlapi from "@/typescript/urlapi/url";
//import StatusCode from "../components/statuscode/error.vue";
import group from "@/typescript/group/group";
import AlertPopup from "@/components/alert/alertPopup.vue";
import AltStatus from "@/components/alert/alertStatus.vue";
import AdsInventories from "@/typescript/inventory";
import Dropdown from "@/components/input/dropdownapidata.vue";
import Login from "@/typescript/login";
import VueCookies from "vue-cookies";
import cookiesPath from "@/typescript/urlapi/cookiesPath";
export default {
  data() {
    return {
      blnloadError: false,
      blnloadConfirmDelet: false,
      strSearch: "",
      blnCanDelete: null,
      changeCampusList: null,
      blnischildinactive: false,
      timerCountHover: 0,
      locales: null,
      timerEnabled: false,
      timerCount: 0,
      progress: 0,
      configDisplay: "SelectedListAgency",
      strSearchField: [
        "strAgencyNameEN",
        "strAgencyNameTH",
        "strAgencyInitialEN",
        "strAgencyInitialTH",
      ],
      blnAltDupicateAcademic: false, //popup แสดงว่ามีข้อมูลซ้ำ
      typeSchema: typeSchema,
      blnPopupConfirmDelete: false, //popup ยืนยันต้องการลบข้อมูล
      request: request,
      listRelate: [], //รายการข้อมูลที่มีความสัมพันธ์
      intPinCount: 3, //จำนวนการครั้งที่สามารถ login ได้
      strTel: null, //เบอร์โทรติดต่อเจ้าหน้าที
      intWrongLogin: 0, //จำนวนครั้งที่ login ผิด
      intPinRemainingnumber: 0, //จำนวนครั้งคงเหลือ
      ListAgency: {},
      Listcampus: {},
      formInactive: [], //Input disabled
      listSearch: {},
      action: "Insert",
      strSMSGsuccess: "",
      blnAltStatus: false, //Alt Status Success
      blnAltStatusunsucess: false, //Alt Status unsuccess
      blnAltLoadingData: false, //Alt Status logint
      blnAltLoading: false, //Alt Status logint
      blnnotedit: false,
      blnnotdelete: false,
      blnnotaddchild: false,
      blnstatusOpent: false,
      hover: false, //hover alt
      blnopenConfigDisplay: false,
      intCampusId: null,
      AgencyId: null,
      strCampusNameENLabel: "", //ชื่อวิทยาเขต
      strCampusNameTHLabel: "", //ชื่อวิทยาเขต
      formStateby: {},
      formdata: {
        strCampusNameEN: "",
      },
      formdataDummy: {
        strCampusNameEN: "",
      },
      blnOpenform: false,
      activeFiltersSelected: [],
      strRootname: "",
      strAgencyRoot: [], //เก็บ Root ทั้งหมด
      CheckDeletedData: false, //เช็คว่าข้อมูลถูกลบไปแล้วหรือไม่
      RootID: "",
      ListDummy: {},
      blnOpenFilter: false,
      updateAdsInventories: null,
      listAgencyFilter: [
        {
          list: [
            { name: "19" },
            {
              data: [
                { id: null, lang: "79" }, //all
                { id: 1, lang: "61" }, //open
                { id: 0, lang: "62" }, //close
              ],
            },
            { state: "intAgencyStatus" },
          ],
        },
      ],
      msgalertDataDuplicate: [],
      errorMsg: "",
    };
  },
  components: {
    Relate,
    Header,
    Form,
    AgengyTable,
    Filter,
    AlertPopup,
    AltStatus,
    //StatusCode,
    Dropdown,
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    hover(value) {
      if (value == false) {
        setTimeout(() => {
          this.timerCountHover++;
        }, 1000);
      }
    },
    timerCountHover: {
      handler(value) {
        if (value < 2 && this.timerEnabled && this.hover == true) {
          setTimeout(() => {
            this.timerCount++;
          }, 1000);
        }
        if (value >= 2) {
          this.blnAltStatusunsucess = false;
          this.blnAltLoadingData = false;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
    // timerCount: {
    //   handler(value) {
    //     if (value < 10 && this.timerEnabled && this.progress < 75) {
    //       setTimeout(() => {
    //         this.timerCount++;

    //         this.progress += 5 * this.timerCount;
    //       }, 200);
    //     }
    //   },

    //   immediate: true, // This ensures the watcher is triggered upon creation
    // },
  },
  beforeCreate() {
    AdsInventories.blnCloseform = false;
  },
  mounted() {
    // console.log(this.$store.state.intCampusOfDepartment);
    //โหลด campus
    try {
      axios.get(urlapi + APICampus.getCampusList).then((res) => {
        // console.log(res.data);
        this.Listcampus = res.data;
        if (
          this.$store.state.intCampusOfDepartment == null ||
          this.$store.state.intCampusOfDepartment == undefined
        ) {
          this.intCampusId = res.data.data[0].strID;
        } else {
          this.intCampusId = this.$store.state.intCampusOfDepartment;
        }
        this.$store.state.intCampusOfDepartment = this.intCampusId;
      });

      if (AdsInventories.blnCloseform == true) {
        setTimeout(() => {
          this.processLoadingData();
        }, 1000);
      } else {
        this.updateAdsInventories = setInterval(() => {
          // console.log(AdsInventories.blnCloseform);
          if (AdsInventories.blnCloseform == false) {
            this.openform(false);
            AdsInventories.blnCloseform = true;
          }
        }, 300);
      }
    } catch (error) {
      this.blnAltLoadingData = true;
      this.timerCountHover = 0;
      this.timerEnabled = true;
      this.hover = false;
      setTimeout(() => {
        this.blnAltLoadingData = false;
      }, 5000);
      console.log(error);
    }
  },
  unmounted() {
    clearInterval(this.updateAdsInventories);
  },
  // mounted() {},
  beforeUpdate() {
    //เรียกข้อมูลจาก local
    this.locales = localStorage.getItem("language");
    //ถ้าไม่เจอค่า
    if (this.locales == null || this.locales == "") {
      //ตั้งค่าเริ่มต้นเป็นภาษาอังกฤษ
      this.locales = "en";
    }
    if (this.locales == "en") {
      this.changeCampusList = true;
    } else if (this.locales == "th") {
      this.changeCampusList = false;
    }
  },
  methods: {
    // confirm popUp delete
    ConfirmDelete() {
      // console.log("click button delete");
      this.blnError = false;
      this.$refs.AlertPopup.blnShowMSG = false;
      this.blnPopupConfirmDelete = !this.blnPopupConfirmDelete;
      this.$refs.AlertPopup.password = null;
    },
    // ปิด popUp Duplicate
    closePopup() {
      this.blnAltDupicateAcademic = false;
    },
    // แสดง popUp Duplicate
    checkPopup() {
      this.blnAltDupicateAcademic = !this.blnAltDupicateAcademic;
    },
    // set label campus เพื่อแสดงตอนหน้า form
    async setCampusLabel(intCampusId) {
      var datadummy = { ...this.Listcampus };
      try {
        var cp = datadummy.data?.filter(function (elem) {
          if (elem.strID.toString() === intCampusId.toString()) {
            return true;
          }
        });
        this.strCampusNameENLabel = cp?.[0].strCampusNameEN;
        this.strCampusNameTHLabel = cp?.[0].strCampusNameTH;
      } catch (error) {
        this.blnAltLoadingData = true;
        this.timerCountHover = 0;
        this.timerEnabled = true;
        this.hover = false;
        setTimeout(() => {
          this.blnAltLoadingData = false;
        }, 5000);
        throw new Error(error);
      }
    },
    // skeleton loading ข้อมูล campus
    async processLoadingData() {
      this.listRelate = [];
      this.progress = 0;
      this.timerCountHover = 0;
      this.timerEnabled = true;
      await this.fetchListAgency().then(() => {
        this.setCampusLabel(this.intCampusId).then(() => {
          this.fetchCheckDeleteAgency().then(() => {
            this.processData().then(() => {
              setTimeout(() => {
                if (this.blnloadError == false) {
                  this.progress = 100;
                }
              }, 1000);
            });
          });
        });
      });
    },
    //จัดการข้อมูลแล้วไปแสดงผลในตาราง
    async processData() {
      setTimeout(() => {
        try {
          this.ListAgency.data = group.unflatten(
            this.ListAgency.data,
            "intAgencyParent"
          );
          if (
            localStorage.getItem(this.configDisplay) == null ||
            localStorage.getItem(this.configDisplay) == "undefined"
          ) {
            localStorage.setItem(
              this.configDisplay,
              JSON.stringify(this.ListAgency.defaultField)
            );
          }

          this.ListDummy = { ...this.ListAgency.data };

          this.progress += 10;
          this.timerCount = 0;
          this.timerEnabled = true;
          if (this.$refs.table != undefined) {
            if (localStorage.getItem(this.configDisplay) != "undefined") {
              // ค่า config ไม่เป็น undefined
              this.$refs.table.setSelectedList(
                JSON.parse(localStorage.getItem(this.configDisplay))
              );
            } else {
              // ค่า config เป็น undefined ให้ใช้ config จาก defaultfield
              // และ set ค่า config นั้นไว้
              this.$refs.table.setSelectedList(this.ListAgency.defaultField);
              localStorage.setItem(
                this.configDisplay,
                JSON.stringify(this.ListAgency.defaultField)
              );
            }
          }
          setTimeout(() => {
            if (this.$refs.filter != undefined) {
              this.$refs.filter.Clear();
            }
          }, 100);

          return true;
        } catch (err) {
          this.blnAltLoadingData = true;
          this.timerCountHover = 0;
          this.timerEnabled = true;
          this.hover = false;
          setTimeout(() => {
            this.blnAltLoadingData = false;
          }, 5000);
          console.log(err);
        }
      }, 100);
    },

    // confirm popUp ข้อมูลซ้ำ
    confirmAltPopup(state) {
      if (state == false) {
        this.blnAltDupicateAcademic = false;
      } else if (state == true) {
        if (this.action == "Insert") {
          this.fetchInsertAgency(this.formStateby);
          this.blnAltDupicateAcademic = false;
        } else if (this.action == "Update") {
          this.blnAltDupicateAcademic = false;
          this.fetchUpdateAgency(this.formStateby);
        }
      }
    },

    //เปิด/ปิด ฟอร์ม
    async openform(state, campus, parent) {
      this.activeFiltersSelected = "";
      if (state == false) {
        this.strRootname = "";
        this.progress = 0;
        // this.ListAcademic = [];
        this.formdata = {};
        this.formdataDummy = {};
        await this.processLoadingData();
        if (this.$refs.header != null) {
          this.$refs.header.onClear();
        }
      } else if (state == true) {
        await this.fetchgetAgencyRoot(parent);
        if (this.action == "Insert") {
          this.listRelate = [];
          this.formdata = {};
          this.formInactive = [];
          var activeDelete = {
            name: "blnDeleteinForm",
            inactive: true,
            tooltip: false,
          };
          var activeConfirm = {
            name: "intAgencyStatus",
            blnConfirm: false,
          };
          this.formInactive.push(activeConfirm);
          this.formInactive.push(activeDelete);

          this.formdata = {
            strCountrycode: "66",
            strCampusid: campus,
            strCampusNameENLabel: this.strCampusNameENLabel,
            strCampusNameTHLabel: this.strCampusNameTHLabel,
            intAgencyParent: parent.strID == undefined ? 0 : parent.strID,
            strAgencyWebsite: "https://",
            strAgencyFacebook: "https://www.facebook.com/",
            Rootname: this.strAgencyRoot,
          };
        }
        if (parent != 0 || this.action == "Update") {
          this.formdata.strCampusNameENLabel = this.strCampusNameENLabel;
          this.formdata.strCampusNameTHLabel = this.strCampusNameTHLabel;
          if (this.action == "Update" && this.strAgencyRoot.length > 0) {
            this.formdata.Rootname = this.strAgencyRoot;
            this.formInactive = [];
            if (this.strAgencyRoot.length < 2) {
              var activeConfirmfasle = {
                name: "intAgencyStatus",
                showSub: false,
              };
              this.formInactive.push(activeConfirmfasle);
            } else if (this.strAgencyRoot.length > 1) {
              var activeConfirmRoot = {
                name: "intAgencyStatus",
                showSub: true,
              };
              this.formInactive.push(activeConfirmRoot);
            }

            var activeConbln = {
              name: "intAgencyStatus",
              blnConfirm: true,
            };
            this.formInactive.push(activeConbln);
            this.formdata.Rootname = this.strAgencyRoot;
          }

          // console.log(this.formdata.Rootname);

          // let root =
          // this.action === "Update"
          //   ? this.formdata
          //   : this.ListDummy.filter((ele) => {
          //       return ele.strID === parent;
          //     });

          // ถ้าลูกเปิดอยู่ จะ disable ToggleStatus ตัวแม่
          if (this.blnischildinactive == true) {
            let inactiveStatus = {
              name: "intAgencyStatus",
              inactive: true,
              firstcheck: true,
            };
            this.formInactive.push(inactiveStatus);
            // console.log(this.formInactive);
          }
          this.strRootname =
            (this.action == "Update" ? this.$t("238") : this.$t("237")) +
            " " +
            (this.locales == "en"
              ? parent.strAgencyNameEN
              : parent.strAgencyNameTH);

          try {
            //ถ้ามีการใช้งานอยู่ หรือ ไม่สามารถลบได้
            if (this.listRelate.used == true || this.blnCanDelete == false) {
              var inactiveDelete = {
                name: "blnDeleteinForm",
                inactive: true,
                tooltip: false,
              };
              this.formInactive.push(inactiveDelete);
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
      this.blnOpenform = state;
    },
    // เลือกCampus
    async selectCampus(campusId) {
      this.intCampusId = campusId.id;
      this.$store.state.intCampusOfDepartment = this.intCampusId;
      this.progress = 0;
      this.timerCount = 0;
      await this.setCampusLabel(this.intCampusId);
      this.progress = 0;
      this.processLoadingData();
    },

    //อัพเดตข้อมูล department
    async fetchUpdateAgency(data) {
      if (this.$refs.header != null) {
        this.$refs.header.onClear();
      }
      const formData = new FormData();
      // console.log(data.confirmintAgencyStatus);
      //แปลงข้อมูลจาก object เป็น formdata
      Object.keys(data).forEach((key) => {
        //console.log(key);
        //console.log(`${key}: ${data[key]}`);
        if (
          key !== "strPicpath" &&
          key !== "confirmintCpstatus" &&
          key !== "strCountrycode" &&
          key !== "strAgencyTelTH" &&
          key !== "strAgencyTelEN" &&
          key !== "strAgencyFaxTH" &&
          key !== "strAgencyFaxEN" &&
          key !== "intSubdistrict"
        ) {
          formData.append(key, data[key]);
        } else if (key === "strPicpath") {
          // console.log(value);
          formData.append(key, data[key]);
        } else if (key === "strAgencyTelTH") {
          // formData.append(key, data[key]);
          let list = {
            strCountryCode: data["strCountrycode"],
            strAgencyTelTH: data[key],
          };
          formData.append(key, JSON.stringify(list));
        } else if (key === "strAgencyTelEN") {
          // formData.append(key, data[key]);
          let list = {
            strCountryCode: data["strCountrycode"],
            strAgencyTelEN: data[key],
          };
          formData.append(key, JSON.stringify(list));
        } else if (key === "strAgencyFaxTH") {
          // formData.append(key, data[key]);
          let list = {
            strCountryCode: data["strCountrycode"],
            strAgencyFaxTH: data[key],
          };
          formData.append(key, JSON.stringify(list));
        } else if (key === "strAgencyFaxEN") {
          // formData.append(key, data[key]);
          let list = {
            strCountryCode: data["strCountrycode"],
            strAgencyFaxEN: data[key],
          };
          // console.log(list.toString());
          formData.append(key, JSON.stringify(list));
        } else if (key === "intSubdistrict") {
          // console.log(key);
          // console.log(data[key].id == undefined);
          formData.append(
            "intProvince",
            data[key].id != undefined
              ? data[key].id.intProvinceID
              : data["strSubdistrict"].id.intProvinceID
          );
          formData.append(
            "intDistrict",
            data[key].id != undefined
              ? data[key].id.intDistrictID
              : data["strSubdistrict"].id.intDistrictID
          );
          formData.append(
            "intSubdistrict",
            data[key].id != undefined
              ? data[key].id.intSubdistrictID
              : data["strSubdistrict"].id.intSubdistrictID
          );
          formData.append(
            "strPostcode",
            data[key].id != undefined
              ? data[key].id.strPostcode.toString()
              : data["strSubdistrict"].id.strPostcode.toString()
          );
        }
      });

      // for (let [key, value] of formData) {
      //   console.log(`${key}: ${value}`);
      // }
      // var object = {};
      // formData.forEach(function (value, key) {
      //   object[key] = value;
      // });
      // console.log(JSON.stringify(object));
      // console.log(
      //   urlapi +
      //     APIAgency.updateAgency +
      //     this.intCampusId +
      //     "/" +
      //     this.AgencyId +
      //     "?MemberID=1" +
      //     (data.confirmintAgencyStatus !== undefined
      //       ? "&Confirm=" + data.confirmintAgencyStatus
      //       : '"&Confirm=" + false')
      // );

      let isEqual = JSON.stringify(this.formdataDummy) === JSON.stringify(data);

      if (isEqual == true) {
        await axios
          .get(
            urlapi +
              APIAgency.getAgencyByID +
              this.intCampusId +
              "/" +
              this.AgencyId
          )
          .then((res) => {
            // console.log(res);
            if (
              res.data.message ==
              "Not found ,Please check mapping Id in interface"
            ) {
              this.blnOpenform = false;
              this.blnnotedit = true;
              setTimeout(() => {
                this.blnnotedit = false;
                this.processLoadingData();
              }, 5000);
            } else {
              this.blnOpenform = false;
              this.blnAltStatus = true;
              this.strSMSGsuccess = "27";
              this.progress = 0;
              setTimeout(() => {
                this.processLoadingData();
                this.blnAltStatus = false;
              }, 2000);
            }
          })
          .catch(() => {
            this.blnAltStatusunsucess = true;
            this.timerCount = 0;
            this.hover = true;
            setTimeout(() => {
              this.blnAltStatusunsucess = false;
            }, 5000);
          });
      } else {
        await axios
          .put(
            urlapi +
              APIAgency.updateAgency +
              this.intCampusId +
              "/" +
              this.AgencyId +
              "?MemberID=" +
              AdsInventories.userinfo.intMemberID +
              (data.confirmintAgencyStatus !== undefined
                ? "&Confirm=" + data.confirmintAgencyStatus
                : '"&Confirm=" + false'),
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          )
          .then((res) => {
            // console.log(res.data);
            if (res.data.edited == true) {
              this.blnOpenform = false;
              this.blnAltStatus = true;
              this.strSMSGsuccess = "27";

              setTimeout(() => {
                this.blnAltStatus = false;
              }, 2000);
              this.progress = 0;
              //delay 1 sec
              setTimeout(() => {
                //ถ้าบันทึกสำเร็จ ทำการเรียกข้อมูลใหม่อีกครั้ง
                this.processLoadingData();
              }, 1000);
            }
            if (res.data.edited == false) {
              //ถ้าไม่สามารถ edit ได้ แล้วชื่อไม่ซ้ำ
              //กดลบไปแล้ว กดEdit แล้วกดตกลง
              if (
                res.data.message ==
                "Cannot edit ,Please check mapping Id in interface"
              ) {
                // console.log("ไม่ซ้ำ");
                this.blnOpenform = false;
                this.blnnotedit = true;
                setTimeout(() => {
                  this.blnnotedit = false;
                  this.processLoadingData();
                }, 4000);
              }

              this.$refs.form.detectalertDataDuplicate(
                this.msgalertDataDuplicate
              );
            }
            if (res.data.code === 500 || res.data.edited == false) {
              this.blnAltStatusunsucess = true;
              this.timerCount = 0;
              this.hover = true;

              setTimeout(() => {
                this.blnAltStatusunsucess = false;
              }, 5000);
            }
          })
          .catch(() => {
            this.blnAltStatusunsucess = true;
            this.timerCount = 0;
            this.hover = true;
            setTimeout(() => {
              this.blnAltStatusunsucess = false;
            }, 5000);
          });
      }
    },

    // เรียกข้อมูลหน่วยงานด้วย ID
    async fetchGetAgencybyId(AgencyId) {
      var resused = await axios.get(
        urlapi + APIAgency.checkUsedAgency + AgencyId
      );
      // console.log(urlapi + APIAgency.checkUsedAgency + AgencyId);
      // console.log(resused);
      this.listRelate = { ...resused.data };

      this.AgencyId = AgencyId;
      await axios
        .get(
          urlapi + APIAgency.getAgencyByID + this.intCampusId + "/" + AgencyId
        )
        .then((res) => {
          // console.log(res.data);
          if (
            res.data.message ==
            "Not found ,Please check mapping Id in interface"
          ) {
            this.blnOpenform = false;
            this.blnnotedit = true;
            setTimeout(() => {
              this.blnnotedit = false;
              this.processLoadingData();
            }, 5000);
          } else {
            this.action = "Update";
            this.formdata = res.data.data;
            this.formdataDummy = { ...res.data.data };
            this.setCampusLabel(this.intCampusId);
            this.blnischildinactive = res.data.ischildactive;
            this.blnCanDelete = res.data.iscandelete;
            for (var d in this.formdata) {
              //แปลงเบอร์โทร
              if (d == "strAgencyTelTH") {
                let tel = JSON.parse(this.formdata[d]);
                // console.log(tel);
                this.formdata[d] = tel.strAgencyTelTH;
                this.formdata["strCountrycode"] = tel.strCountryCode;
              }

              //แปลงแฟกซ์
              if (d == "strAgencyFaxTH") {
                if (this.formdata[d] !== "") {
                  let tel = JSON.parse(this.formdata[d]);
                  // console.log(tel);
                  this.formdata[d] = tel.strAgencyFaxTH;
                  this.formdata["strCountrycode"] = tel.strCountryCode;
                }
              }

              //แปลงเบอร์โทร
              if (d == "strAgencyTelEN") {
                let tel = JSON.parse(this.formdata[d]);
                // console.log(tel);
                this.formdata[d] = tel.strAgencyTelEN;
                this.formdata["strCountrycode"] = tel.strCountryCode;
              }

              //แปลงแฟกซ์
              if (d == "strAgencyFaxEN") {
                if (this.formdata[d] !== "") {
                  let tel = JSON.parse(this.formdata[d]);
                  // console.log(tel);
                  this.formdata[d] = tel.strAgencyFaxEN;
                  this.formdata["strCountrycode"] = tel.strCountryCode;
                }
              }
            }

            // this.blnOpenform = true;
            var inactiveConfirm = {
              name: "intAgencyStatus",
              blnConfirm: true,
            };
            this.formInactive.push(inactiveConfirm);
            this.openform(true, this.intCampusId, this.formdata);
          }
        });

      //console.log(this.strAgencyRoot[0].strAgencyname)
    },

    //แสดงรูท
    async fetchgetAgencyRoot(AgencyID) {
      this.blnOpenform = false;
      this.strAgencyRoot = [];
      this.RootID = AgencyID.strID == undefined ? AgencyID : AgencyID.strID;
      await axios
        .get(urlapi + APIAgency.getAgencyRoot + this.RootID)
        .then((resRoot) => {
          if (resRoot.data.data.length > 0) {
            this.strAgencyRoot = resRoot.data.data;
            // console.log(resRoot.data.data);
            // ถ้าลูกเปิดอยู่ จะdisable ToggleStatus ตัวแม่
            if (resRoot.data.ischildactive != null) {
              this.formInactive = [];
              let inactiveStatus = {
                name: "intAgencyStatus",
                inactive: true,
              };
              this.formInactive.push(inactiveStatus);
            }
          } else if (resRoot.data.data.length == 0) {
            this.strAgencyRoot = [];
          }
        });
      // console.log(this.strAgencyRoot);
    },

    //ตรวจสอบข้อมูลซ้ำ
    async fetchCheckDupicateAgency(data) {
      this.formStateby = data;
      // console.log(data);

      try {
        var res = await axios.post(urlapi + APIAgency.duplicate, data);
        // console.log(res);
        if (res.data.agencynameEN == false && res.data.agencynameTH == false) {
          if (this.action == "Insert") {
            await this.fetchInsertAgency(data);
          } else if (this.action == "Update") {
            await this.fetchUpdateAgency(data);
          }
        } else {
          this.msgalertDataDuplicate = [];
          if (res.data.agencynameEN == true) {
            let Message = { name: "strAgencyNameEN", msg: "221" };
            this.msgalertDataDuplicate.push(Message);
          }
          if (res.data.agencynameTH == true) {
            let Message = { name: "strAgencyNameTH", msg: "221" };
            this.msgalertDataDuplicate.push(Message);
          }
          // console.log(this.msgalertDataDuplicate)
          this.$refs.form.detectalertDataDuplicate(this.msgalertDataDuplicate);
        }

        // else this.blnAltDupicateAcademic = true
      } catch (error) {
        console.log(error);
        this.blnAltLoadingData = true;
        this.timerCountHover = 0;
        this.timerEnabled = true;
        this.hover = false;
        setTimeout(() => {
          this.blnAltLoadingData = false;
        }, 5000);
        throw new Error(error);
      }
      // try {
      //   var res = await axios.post(urlapi + APIAgency.duplicate, data);
      //   console.log(res);
      //   if (res.data.agencynameEN == true || res.data.agencynameTH == true) {
      //     if (this.action == "Insert") {
      //       this.blnAltDupicateAcademic = true;
      //     } else if (this.action == "Update") {
      //       if (
      //         this.formdataDummy.strAgencyNameEN == data.strAgencyNameEN &&
      //         this.formdataDummy.strAgencyNameTH == data.strAgencyNameTH
      //       ) {
      //         this.blnAltDupicateAcademic = false;
      //         await this.fetchUpdateAgency(data);
      //       } else {
      //         this.blnAltDupicateAcademic = true;
      //       }
      //     }
      //   } else if (
      //     res.data.agencynameEN == false &&
      //     res.data.agencynameTH == false
      //   ) {
      //     if (this.action == "Insert") {
      //       await this.fetchInsertAgency(data);
      //     } else if (this.action == "Update") {
      //       await this.fetchUpdateAgency(data);
      //     }
      //   }
      // } catch (error) {
      // this.blnAltLoadingData = true;
      // this.timerCountHover = 0;
      // this.timerEnabled = true;
      // this.hover = false;
      // setTimeout(() => {
      //   this.blnAltLoadingData = false;
      // }, 2000);
      // throw new Error(error);
      // }
      return true;
    },
    // popUp confirm เพิ่มข้อมูล
    async confirmInsert(event) {
      // console.log(event);
      if (event == true) {
        await this.fetchInsertAgency();
      }
    },

    // ตรวจสอบว่าหน่วยงานนี้ลบได้หรือไม่
    async CheckDeletedata(data) {
      // console.log(data.intAgencyParent);
      if (data.intAgencyParent == 0) {
        this.CheckDeletedData = false;
      } else if (data.intAgencyParent != 0 || data.intAgencyParent != null) {
        await axios
          .get(
            urlapi +
              APIAgency.getAgencyByID +
              this.intCampusId +
              "/" +
              data.intAgencyParent
          )
          .then((res) => {
            // console.log(res.data);
            if (
              res.data.message ==
              "Not found ,Please check mapping Id in interface"
            ) {
              this.CheckDeletedData = true;
              this.blnOpenform = false;
              this.blnnotaddchild = true;
              setTimeout(() => {
                this.blnnotaddchild = false;
                this.processLoadingData();
              }, 5000);
            }
          });
      }
    },

    // เพิ่มข้อมูลหน่วยงาน
    async fetchInsertAgency(data) {
      await this.CheckDeletedata(data);
      data.strCampusid = this.intCampusId;

      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        //console.log(key);
        //console.log(`${key}: ${data[key]}`);
        if (
          key !== "strPicpath" &&
          key !== "confirmintCpstatus" &&
          key !== "strCountrycode" &&
          key !== "strAgencyTelTH" &&
          key !== "strAgencyTelEN" &&
          key !== "strAgencyFaxTH" &&
          key !== "strAgencyFaxEN" &&
          key !== "intSubdistrict"
        ) {
          formData.append(key, data[key]);
        } else if (key === "strPicpath") {
          // console.log(value);
          formData.append(key, data[key]);
        } else if (key === "strAgencyTelTH") {
          // formData.append(key, data[key]);
          let list = {
            strCountryCode: data["strCountrycode"],
            strAgencyTelTH: data[key],
          };
          formData.append(key, JSON.stringify(list));
        } else if (key === "strAgencyTelEN") {
          // formData.append(key, data[key]);
          let list = {
            strCountryCode: data["strCountrycode"],
            strAgencyTelEN: data[key],
          };
          formData.append(key, JSON.stringify(list));
        } else if (key === "strAgencyFaxTH") {
          // formData.append(key, data[key]);
          let list = {
            strCountryCode: data["strCountrycode"],
            strAgencyFaxTH: data[key],
          };
          formData.append(key, JSON.stringify(list));
        } else if (key === "strAgencyFaxEN") {
          // formData.append(key, data[key]);
          let list = {
            strCountryCode: data["strCountrycode"],
            strAgencyFaxEN: data[key],
          };
          // console.log(list.toString());
          formData.append(key, JSON.stringify(list));
        } else if (key === "intSubdistrict") {
          // console.log(key);
          // console.log(data[key].id == undefined);
          formData.append(
            "intProvince",
            data[key].id != undefined
              ? data[key].id.intProvinceID
              : data["strSubdistrict"].id.intProvinceID
          );
          formData.append(
            "intDistrict",
            data[key].id != undefined
              ? data[key].id.intDistrictID
              : data["strSubdistrict"].id.intDistrictID
          );
          formData.append(
            "intSubdistrict",
            data[key].id != undefined
              ? data[key].id.intSubdistrictID
              : data["strSubdistrict"].id.intSubdistrictID
          );
          formData.append(
            "strPostcode",
            data[key].id != undefined
              ? data[key].id.strPostcode.toString()
              : data["strSubdistrict"].id.strPostcode.toString()
          );
        }
      });
      if (this.CheckDeletedData == false) {
        try {
          var res = await axios.post(
            urlapi +
              APIAgency.insertAgency +
              AdsInventories.userinfo.intMemberID +
              (data.confirmintAgencyStatus !== undefined
                ? "&Confirm=" + data.confirmintAgencyStatus
                : '"&Confirm=" + false'),
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
          // console.log(data.confirmintAgencyStatus);
          // var res = await axios.post(
          //   urlapi + APIAgency.insertAgency,
          //   data
          // );
          // console.log(res);
          if (res.data.inserted == true) {
            this.blnOpenform = false;
            this.blnAltStatus = true;
            this.strSMSGsuccess = "26";
            setTimeout(() => {
              this.blnAltStatus = false;
            }, 2000);
            this.progress = 0;
            //delay 1 sec
            setTimeout(() => {
              //ถ้าบันทึกสำเร็จ ทำการเรียกข้อมูลใหม่อีกครั้ง
              this.processLoadingData();
            }, 1000);
          }
          if (res.data.code === 500 || res.data.inserted == false) {
            this.blnAltStatusunsucess = true;
            this.timerCount = 0;
            this.hover = true;
            setTimeout(() => {
              this.blnAltStatusunsucess = false;
            }, 5000);
          }
        } catch (error) {
          this.blnAltStatusunsucess = true;
          this.hover = false;
          this.hover = true;
          setTimeout(() => {
            this.blnAltStatusunsucess = false;
          }, 5000);
          throw new Error(error);
        }
      }
    },

    //ลบข้อมูลด้วย ID
    async fetchDeleteAgency(state) {
      try {
        this.blnloadConfirmDelet = true;
        if (state === false) {
          // console.log(state);
          this.blnPopupConfirmDelete = !this.blnPopupConfirmDelete;
          this.blnloadConfirmDelet = false;
        } else {
          if (
            this.$refs.AlertPopup.password != null &&
            this.$refs.AlertPopup.password != ""
          ) {
            var strUsername = AdsInventories.userinfo.strUsername.split("@");
            var reslogin = await Login.login(
              strUsername[0],
              state,
              axios,
              urlapi
            );
            var login = {
              username: strUsername[0],
              password: state,
            };

            // console.log(reslogin);
            if (reslogin.logined == true) {
              this.intPinCount = reslogin.pincode;
              this.strTel = reslogin.tel;
              // this.blnPopupConfirmDelete = false;
              // console.log(
              //   APIAgency.deleteAgency + this.intCampusId + "/" + this.AgencyId
              // );
              await axios
                .put(
                  urlapi +
                    APIAgency.deleteAgency +
                    this.intCampusId +
                    "/" +
                    this.AgencyId,
                  login
                )
                .then((res) => {
                  // console.log(res.data);
                  if (res.data.candelete == true) {
                    
                    this.blnError = false;
                    this.blnPopupConfirmDelete = false;
                    this.blnAltStatus = true;
                    this.strSMSGsuccess = "28";
                    this.blnOpenform = false;
                    this.progress = 0;

                    //delay 1 sec
                    setTimeout(() => {
                      if (this.$refs.header != undefined) {
                        this.$refs.header.onClear();
                      }
                      this.blnAltStatus = false;
                      this.processLoadingData();
                    }, 1000);

                    // this.openform(false, 0, 0);
                    // this.$refs.table.checkstateofdelete(true);
                  } else if (res.data.candelete == false) {
                    this.blnOpenform = false;
                    this.timerCountHover = 0;
                    this.timerEnabled = true;
                    this.hover = false;
                    this.blnPopupConfirmDelete = false;
                    // reset PIN หลังจากDelete
                    this.$refs.AlertPopup.password = null;

                    //ถ้าลบไปแล้ว ไม่สามารถลบซ้ำได้อีก
                    if (
                      res.data.message ==
                      "Cannot delete ,Please check mapping Id in interface"
                    ) {
                      //ข้อมูลนี้เพิ่งถูกลบไป ไม่สามารถลบได้
                      this.blnnotdelete = true;
                      this.progress = 0;
                      setTimeout(() => {
                        this.blnnotdelete = false;
                        this.processLoadingData();
                      }, 5000);
                    }
                    if (
                      res.data.message == "Agency data is open cannot delete"
                    ) {
                      //ข้อมูลนี้มีสถานะเป็นเปิด ไม่สามารถลบได้
                      this.blnstatusOpent = true;
                      setTimeout(() => {
                        this.blnstatusOpent = false;
                        this.processLoadingData();
                      }, 5000);
                    } else {
                      //ข้อมูลนี้เพิ่งถูกใช้งาน ไม่สามารถลบได้
                      this.blnAltLoadingData = true;
                      setTimeout(() => {
                        this.blnAltLoadingData = false;
                        this.processLoadingData();
                      }, 5000);
                    }
                  } else {
                    this.blnAltLoadingData = true;
                    this.timerCountHover = 0;
                    this.timerEnabled = true;
                    this.hover = false;
                    this.blnPopupConfirmDelete = false;
                    // reset PIN หลังจากDelete
                    this.$refs.AlertPopup.password = null;
                    setTimeout(() => {
                      this.blnAltLoadingData = false;
                    }, 5000);
                  }
                })
                .catch((e) => {
                  this.blnAltLoadingData = true;
                  this.timerCountHover = 0;
                  this.timerEnabled = true;
                  this.hover = false;
                  setTimeout(() => {
                    this.blnAltLoadingData = false;
                  }, 5000);
                  console.log(e);
                });

              // reset PIN หลังจากDelete
              this.$refs.AlertPopup.password = null;
            } else {
              this.intPinCount = reslogin.pincode;
              this.strTel = reslogin.tel;
              this.intWrongLogin = reslogin.WrongLogin;
              // console.log(this.intWrongLogin);
              this.blnError = true;
              this.$refs.AlertPopup.blnShowMSG = true;
              this.intPinRemainingnumber =
                this.intPinCount - this.intWrongLogin;
              this.errorMsg = this.$t("357", {
                number: this.intPinRemainingnumber,
              });
              // this.$refs.table.checkstateofdelete(false);
              // setTimeout(() => {
              //   this.processLoadingData();
              // }, 1000);
              if (this.intWrongLogin >= this.intPinCount) {
                VueCookies.remove("UserInfo", "/", cookiesPath);
              }
            }
            this.blnloadConfirmDelet = false;
          } else {
            this.blnloadConfirmDelet = false;
            this.errorMsg = this.$t("202", { fieldname: "(PIN)" });
            this.blnError = true;
            this.$refs.AlertPopup.blnShowMSG = true;
          }
        }
      } catch (error) {
        this.$refs.AlertPopup.blnShowMSG = true;
        this.blnloadConfirmDelet = false;
        // this.blnAltLoadingData = true;
        // this.timerCountHover = 0;
        // this.timerEnabled = true;
        // this.hover = false;
        console.log(error);
      }
    },

    // เรียกข้อมูลหน่วยงานทั้งหมดในวิทยาเขตนั้นๆ
    async fetchListAgency() {
      try {
        this.blnloadError = true;
        // this.Listagency = null;
        // console.log(this.intCampusId);
        // console.log(urlapi + APIAgency.getAgencyList + this.intCampusId);
        var res = await axios.get(
          urlapi + APIAgency.getAgencyList + this.intCampusId
        );

        // console.log(res.data.data);
        this.ListAgency = { ...res.data };
        this.ListAgency.data = { ...res.data.data };
        this.blnloadError = false;
        // console.log(this.ListAgency);

        return true;
      } catch (error) {
        console.log(error);
        this.blnAltLoadingData = true;
        this.timerCountHover = 0;
        this.timerEnabled = true;
        this.hover = false;
        setTimeout(() => {
          this.blnAltLoadingData = false;
        }, 5000);
        // throw new Error(error);
      }
    },
    //ตรวจสอบว่าวิทยาเขตสามารถลบได้หรือไหม
    async fetchCheckDeleteAgency() {
      if (Array.isArray(this.ListAgency.data)) {
        this.ListAgency.data.forEach((element, index) => {
          axios
            .get(urlapi + APIAgency.checkdDeleteAgency + element.strID)
            .then((res) => {
              // ถ้าลบได้ เก็บค่าตัวแปร blnStatusDelete เป็น true
              if (res.data.candelete == true) {
                this.ListAgency.data[index]["blnStatusDelete"] = true;
              }
              //ถ้าลบไม่ได้ เก็บค่าตัวแปร blnStatusDelete เป็น false
              else {
                this.ListAgency.data[index]["blnStatusDelete"] = false;
              }
            })
            .catch((e) => {
              this.blnAltLoadingData = true;
              this.timerCountHover = 0;
              this.timerEnabled = true;
              this.hover = false;
              setTimeout(() => {
                this.blnAltLoadingData = false;
              }, 5000);
              console.log(e);
            });
        });
        return true;
      }
    },
    // เพิ่มข้อมูลหน่วยงานย่อย
    async addParent(campus, parent) {
      // console.log(parent.strID);

      await axios
        .get(
          urlapi +
            APIAgency.getAgencyByID +
            this.intCampusId +
            "/" +
            parent.strID
        )
        .then((res) => {
          // console.log(res.data);
          if (
            res.data.message ==
            "Not found ,Please check mapping Id in interface"
          ) {
            this.blnOpenform = false;
            this.blnnotaddchild = true;
            setTimeout(() => {
              this.blnnotaddchild = false;
              this.processLoadingData();
            }, 5000);
          } else {
            // await this.fetchGetAcademicbyId(parent.strID);
            this.action = "Insert";
            this.openform(true, campus, parent);
          }
        });
    },

    //บันทึกการตั้งค่า configdisplay
    onSaveConfigDisplay(SelectedList) {
      localStorage.setItem(this.configDisplay, JSON.stringify(SelectedList));
    },
    //เรียกบันทึกการตั้งค่า configdisplay
    reloadConfigDisplay() {
      if (this.$refs.table != null) {
        this.$refs.table.setSelectedList(
          JSON.parse(localStorage.getItem(this.configDisplay))
        );
      }
    },
    //ค้นหาข้อมูลใน table
    SearcAgency(event) {
      this.strSearch = event;
      this.$refs.table.SearchListDataTable(event, this.strSearchField);
    },
    //ยกเลิกการค้นหาข้อมูล
    clean() {
      this.strSearch = "";
      this.SearcAgency("");
      //this.filteredData(this.activeFiltersSelected);
    },
    // เปิด Component filter ที่กดจาก header
    openFilter(showFilter) {
      this.blnOpenFilter = showFilter;
    },
    //รับค่าจาก component filter
    filteredData(activeFilters) {
      // console.log(activeFilters);
      this.blnOpenFilter = false;
      //กำหนดรูปแบบการ filter
      this.activeFiltersSelected = activeFilters;
      //ตั้งค่าเริ่มต้น  highlight,expend เป็น false
      Object.values(this.ListDummy).forEach((ele) => {
        ele.highlight = false;
        ele.expend = false;
      });
      //dummy ข้อมูล
      var dummy = { ...this.ListDummy };

      //เรียกฟังก์ชัน filter
      this.filter(activeFilters, dummy);
      // console.log(this.ListAgency.data);
      //call tabel tree component get function setIndexnumber
      this.$refs.table.setIndexnumber();
    },
    //Filter รับค่า จากตัวกรองกับข้อมูลชุดเดิม
    filter(activeFilters, ListDummy) {
      // console.log(ListDummy);
      this.blnOpenFilter = false;
      this.ListAgency.data = Object.values(ListDummy).slice();
      for (var field in activeFilters) {
        this.ListAgency.data = this.filterByList(
          this.ListAgency.data,
          activeFilters[field].state,
          activeFilters[field].id
        );
        // this.ListAgency.data = this.ListAgency.data.filter(
        //   (elem) => elem[activeFilters[field].state] == activeFilters[field].id
        // );
      }

      if (this.$refs.table != null) {
        this.$refs.table.onClean();
      }
      this.$refs.table.SearchListDataTable(this.strSearch);
      this.blnOpenFilter = false;
      this.ListDummy = ListDummy;
    },

    // filter ข้อมูล โดย list ข้อมูล
    filterByList(array, state, key) {
      const getNodes = (result, object) => {
        if (Array.isArray(object.children)) {
          const children = object.children.reduce(getNodes, []);

          if (children.length) {
            object.expend = true;
            result.push({ ...object, children });
            return result;
          }
        }
        if (this.filterSelected(object, state, key)) {
          object.highlight = true;
          object.expend = true;
          result.push(object);
          return result;
        }

        return result;
      };

      return array.slice().reduce(getNodes, []);
    },
    // เลือก filter
    filterSelected(item, filters, event) {
      // console.log(filters);
      return (
        item[filters]
          .toString()
          .toLowerCase()
          .indexOf(event.toString().toLowerCase()) !== -1
      );
    },
    // reloadpage ไปหน้า logout
    reloadpage() {
      Login.logout();
      //ไปยังหน้าแรก
      // setTimeout(() => {
      //   this.$router.push("/login");
      //   this.$router.go();
      // }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-campus {
  width: 350px;
}
.overlay-alertpop {
  background-color: #1515158e;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 150ms ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.zoom-enter-from,
.zoom-leave-to {
  opacity: 0;
}
.zoom-enter-active {
  animation: bounce-in 150ms;
}
.zoom-leave-active {
  opacity: 0;
}
@keyframes bounce-in {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
.popup-container {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  .overlay {
    background-color: rgba(0, 0, 0, 0.263);
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
.form-lg {
  width: 910px !important;
}
.move-to-top-level .layout:nth-child(odd) {
  order: 2;
  margin-bottom: 16px !important;
}
</style>
