<template>
  <img width="24" height="24" :src="imageURL" alt="" />
</template>

<script>
export default {
  props: {
    class: { type: String },
    imageURL: { type: String },
  },
};
</script>

<style>
</style>